<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalItems"
      item-key="id"
      fixed-header
      fixed-footer
      class="elevation-1"
      :loading="loading"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Manifests</v-toolbar-title>
          <v-row align="center">
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-text-field
                v-model="search"
                clearable
                label="Search by container name or manifest number"
                hide-details
                @keyup.enter="getManifests"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-checkbox
                label="Also show finished manifests"
                v-model="alsoShowFinishedManifests"
                :hide-details="true"
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-autocomplete
                v-model="warehouseFilter"
                :items="warehouses"
                item-value="key"
                item-text="value"
                name="Warehouse"
                label="Filter by warehouse"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #item.warehouseId="{ item }">
        {{ getWarehouseNameFromId(item.warehouseId) }}
      </template>
      <template #item.creationDateTime="{ item }">
        {{ formatDateTime(item.creationDateTime) }}
      </template>
      <template #item.isClosed="{ item }">
        <v-icon :color="item.isClosed ? 'success' : 'error'"
          >{{ item.isClosed ? "mdi-check" : "mdi-close" }}
        </v-icon>
      </template>
      <template #group.header="{ group, items, isOpen, toggle }">
        <th colspan="5">
          <v-row align-content="center">
            <v-col class="d-flex align-center">
              <h2 v-if="group">
                <v-icon @click="toggle">
                  {{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
                {{ formatManifestNumber(group) }}
              </h2>
            </v-col>
          </v-row>
        </th>
      </template>
    </v-data-table>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  ContainerApi,
  ContainerManifestViewModel,
  DataApi,
  KeyValueItem,
} from "@/openapi";
import { FooterOptions } from "@/types/types";
import moment from "moment";
import { computed, onBeforeMount, ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";

const containerApi = new ContainerApi(undefined, "");
const dataApi = new DataApi(undefined, "");

const emits = defineEmits<{
  (e: "PageInfoReceived", title: string, items: ToolbarItem[]): void;
}>();

const loading = ref(false);
const totalItems = ref(0);
const manifests = ref<ContainerManifestViewModel[]>([]);
const alsoShowFinishedManifests = ref(false);
const warehouseFilter = ref(0);
const search = ref("");

const warehouses = ref<KeyValueItem[]>([]);
const headers = ref<DataTableHeader[]>([
  {
    text: "Container name",
    value: "name",
  },
  {
    text: "Warehouse",
    value: "warehouseId",
  },
  {
    text: "Creation date/time",
    value: "creationDateTime",
  },
  {
    text: "Total weight",
    value: "totalWeight",
    sortable: false,
  },
  {
    text: "# pieces",
    value: "totalPieces",
    sortable: false,
  },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: ["creationDateTime"],
  sortDesc: [true],
  groupBy: ["manifestId"],
  groupDesc: [true],
  multiSort: false,
  mustSort: true,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100],
  disablePagination: false,
});

const items = computed(() => {
  if (manifests.value.length === 0) {
    return [];
  }

  const containers =
    manifests.value
      .filter(
        (manifest) =>
          manifest.containers !== null && manifest.containers !== undefined,
      )
      .map((manifest) => manifest.containers?.flat())
      .reduce((prev, current) => {
        if (current) {
          current.forEach((element) => {
            prev?.push(element);
          });
        }

        return prev;
      }) ?? [];
  return containers;
});

watch(
  () => options.value,
  async (newVal, oldVal) => {
    await getManifests();
  },
);

watch(
  () => alsoShowFinishedManifests.value,
  async (newVal, oldVal) => {
    await getManifests();
  },
);

watch(
  () => warehouseFilter.value,
  async (newVal, oldVal) => {
    await getManifests();
  },
);

const getWarehouses = async () => {
  try {
    const response = await dataApi.getMainWarehouses();
    warehouses.value = response.data;
  } catch (error: unknown) {
    emitError(
      "An error occurred while retrieving the warehouses from the webservice",
    );
  }
};

const getWarehouseNameFromId = (warehouseId: number) => {
  const warehouse = warehouses.value.find(
    (warehouseKV) => warehouseKV.key === warehouseId,
  );
  if (!warehouse) {
    return "Unknown";
  }

  return warehouse.value;
};

const getManifests = async () => {
  try {
    loading.value = true;
    const response = await containerApi.getContainerManifests(
      options.value.itemsPerPage,
      options.value.page,
      options.value.sortBy.length > 0 ? options.value.sortBy[0] : undefined,
      options.value.sortDesc.length > 0 ? options.value.sortDesc[0] : undefined,
      alsoShowFinishedManifests.value,
      warehouseFilter.value === null ? undefined : warehouseFilter.value,
      search.value === null ? undefined : search.value,
    );
    const pagedResult = response.data;
    totalItems.value = pagedResult.totalAmountOfItems;
    manifests.value = pagedResult.items!;
  } catch (error: unknown) {
    emitError(
      "An error occurred when loading the manifests from the webservice",
    );
  } finally {
    loading.value = false;
  }
};

const refreshData = async () => {
  await Promise.all([getWarehouses(), getManifests()]);
};

const formatDateTime = (datetime: string) => {
  if (!datetime) {
    return "";
  }

  const dateTimeMoment = moment(datetime);
  return dateTimeMoment.format("YYYY-MM-DD HH:mm:ss");
};

const formatManifestNumber = (manifestId: number) => {
  const manifest = manifests.value.find((m) => m.id === manifestId);
  if (!manifest) {
    return "Unknown";
  }

  const creationYear = moment(manifest.creationDateTime).year();
  return `${creationYear}-${manifest.manifestNumber}`;
};

onBeforeMount(() => {
  emits("PageInfoReceived", "Manifests", [
    {
      callback: async () => await refreshData(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ]);

  refreshData();
});
</script>
