import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import * as CustomsExportController from '../api/CustomsExportController';
import CustomsExportShipmentDetailViewNew from './CustomsExportShipmentDetailViewNew.vue.html';
import { CustomsShipmentItemViewModel as TWCustomsShipmentItemViewModel } from '@/api/viewmodels/CustomsShipmentItemViewModel';
import { CustomsShipmentDetailViewModel } from '@/api/viewmodels/CustomsShipmentDetailViewModel';
import AddMrnDialog from './dialogs/AddMrnDialog.vue.html';
import ConfirmShipmentReasonDialog from './dialogs/ConfirmShipmentReasonDialog.vue.html';
import CustomsFlowStepsMixin from '@/mixins/CustomsFlowStepsMixin';
import ShipmentStatusFlow from './ShipmentStatusFlow.vue.html';
import ConfirmShipmentsDialog from './dialogs/ConfirmShipmentsDialog.vue.html';
import UploadPaperworkButton from './UploadPaperworkButton.vue.html';
import {
    CustomsAdminApi,
    CustomsExportApi,
    CustomsPaperworkHandlingApi,
    CustomsShipmentItemViewModel,
    ExportStep,
} from '@/openapi';
import { EmployeeViewModel } from '@/api/viewmodels/EmployeeViewModel';
import { Actions } from '@/config';
import { ConfirmShipmentReasonForm } from './dialogs/ConfirmShipmentReasonDialog';
import ShipmentValueChangesExportDialog from './dialogs/ShipmentValueChangesExportDialog.vue.html';
import { getStatuses } from '@/helpers/statusHelper';
import ShipmentPaperwork from './ShipmentPaperwork.vue.html';
import CountriesMixin from '@/mixins/CountriesMixin';
import ToolbarMixin from '@/mixins/ToolbarMixin';
import FilterParcels from './FilterParcels.vue.html';

import ReturnShipmentButton from './buttons/ReturnShipmentButton.vue.html';
import CustomsInspectionButton from './buttons/CustomsInspectionButton.vue.html';
import HoldShipmentButton from './buttons/HoldShipmentButton.vue.html';
import { emitError } from '@/event-bus';

const customsAdminApi = new CustomsAdminApi(undefined, '');

@Component({
    components: {
        CustomsExportShipmentDetailViewNew,
        AddMrnDialog,
        ShipmentStatusFlow,
        ConfirmShipmentReasonDialog,
        ConfirmShipmentsDialog,
        ShipmentValueChangesExportDialog,
        UploadPaperworkButton,
        ShipmentPaperwork,
        FilterParcels,
        ReturnShipmentButton,
        CustomsInspectionButton,
        HoldShipmentButton,
    },
})
export default class CustomsExportAdmin extends mixins(
    CustomsFlowStepsMixin,
    CountriesMixin,
    ToolbarMixin
) {
    @Prop({ default: '' })
    color!: string;

    api: CustomsExportApi = new CustomsExportApi(undefined, '');
    customsPaperworkHandlingApi: CustomsPaperworkHandlingApi =
        new CustomsPaperworkHandlingApi(undefined, '');

    isLoading = false;
    options: any = {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
    };
    filter: any = {
        step: null,
        shipmentStatusDescription: '',
        parcels: [],
        exportMrn: '',
        loadedConfirmed: 0,
        shipmentConfirmed: null,
        shipmentLoaded: null,
        weight: null,
        showAssigned: false,
        employee: null,
        shipperCountry: null,
        consigneeCountry: null,
    };
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };
    totalItems: Number = 0;
    expanded = [];

    assignedCustomsHandlingItems = 0;

    statusFilter: number[] = [];
    shipmentStatusFilter = [
        'Hold',
        'Return',
        'Customs inspection',
        'Loading confirmed',
        'Waiting for customs release',
        'Customs EXIT released',
    ];

    loadedConfirmedFilter: any[] = [
        { text: 'Show all', value: 0 },
        { text: 'Show Confirmed but not loaded', value: 1 },
        { text: 'Show Loaded but not confirmed', value: 2 },
    ];
    selectedLoadedConfirmed = '';

    booleanFilter = ['Yes', 'No'];

    items: CustomsShipmentItemViewModel[] = [];
    currentHandlingItem: CustomsShipmentItemViewModel | null = null;
    currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;
    selectedHandlingItems: CustomsShipmentItemViewModel[] = [];

    showShipmentValueChangeDialog = false;

    employees: EmployeeViewModel[] = [];

    confirmDocumentOrMRN = 5;

    uploadExcelLTSFile: string | Blob | null = null;

    loadingReadExcelLts = false;
    saveSuccessful = false;

    rules = {
        required: (value: string) =>
            (value && !!value.toString()) || 'Required',
    };

    selectedEmployee: EmployeeViewModel | null = null;
    noEmployeeSelected = false;
    loadingAssignShipmentsToEmployee = false;

    selectedStatus: ExportStep | null = null;
    noStatusSelected = false;
    loadingChangeStatusOfSelected = false;

    valid = false;

    headers = [
        { text: 'Create date', value: 'createDate', width: '10em' },
        { text: 'Employee', value: 'employee', width: '15em' },
        { text: 'Step', value: 'exportFlowStatus', align: 'center' },
        { text: 'Loaded', value: 'isLoaded' },
        { text: 'Confirmed', value: 'isConfirmed' },
        { text: 'Shipment status', value: 'shipmentStatusDescription' },
        {
            text: 'HAWB/Parcel ID',
            value: 'hawb',
            sortable: false,
            width: '15em',
        },
        { text: 'MRN', value: 'exportMrn', width: '18em' },
        { text: 'Temp. WAB Number', value: 'tempWabNumber', width: '15em' },
        { text: 'Final WAB Number', value: 'finalWabNumber', width: '15em' },
        { text: 'Anzahl ', value: 'pieces', sortable: false, align: 'end' },
        {
            text: 'Gewicht ',
            value: 'grossWeight',
            sortable: false,
            align: 'end',
        },
        { text: 'EORI', value: 'eori', sortable: false, width: '15em' },
        { text: 'Versender', value: 'shipper', sortable: false, width: '15em' },
        {
            text: 'Empfänger',
            value: 'consignee',
            sortable: false,
            width: '15em',
        },
        { text: 'Warenwert', value: 'value', sortable: false, align: 'end' },
        { text: 'Währung', value: 'valueCurrency', sortable: false },
        {
            text: 'Warenbeschreibung',
            value: 'articles',
            sortable: false,
            width: '25em',
        },
        {
            text: 'Upload document(s)',
            value: 'documents',
            sortable: false,
        },
    ];

    get hasSelectedHandlingItems() {
        return !!this.selectedHandlingItems?.length;
    }

    get dataTableItems() {
        return this.items.map((c) => ({
            ...c,
            isSelectable: !c.isConfirmed && !this.isLoading,
        }));
    }

    created() {
        this.selectedLoadedConfirmed = this.loadedConfirmedFilter[0].text;
        this.getEmployees();
        this.statusFilter = getStatuses(true, true, true, true, true);

        this.addRefreshToolbarOption(this.reloadShipments);
    }

    async loadShipments(page: number) {
        this.isLoading = true;
        try {
            const response = await customsAdminApi.getAdminShipments({
                page,
                itemsPerPage: this.options.itemsPerPage,
                step: this.filter.step ?? undefined,
                shipmentStatusDescription:
                    this.filter.shipmentStatusDescription ?? undefined,
                parcels: this.filter.parcels,
                exportMrn: this.filter.exportMrn ?? undefined,
                loadedConfirmed: this.filter.loadedConfirmed ?? undefined,
                shipmentLoaded: this.filter.shipmentLoaded ?? undefined,
                shipmentConfirmed: this.filter.shipmentConfirmed ?? undefined,
                weight: this.filter.weight ?? undefined,
                showAssigned: this.filter.showAssigned ?? undefined,
                employee: this.filter.employee ?? undefined,
                shipperCountry: this.filter.shipperCountry ?? undefined,
                consigneeCountry: this.filter.consigneeCountry ?? undefined,
            });

            this.items = response.data.shipments ?? [];
            this.totalItems = response.data.totalAmount ?? 0;
        } catch {
            this.displayError(
                'Something went wrong while retrieving the shipments'
            );
        }
        this.isLoading = false;
    }

    async reloadShipments() {
        this.options.page == 1
            ? await this.loadShipments(this.options.page)
            : (this.options.page = 1);
    }

    timeoutDelay = 0;
    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.loadShipments(this.options.page);
        }, 250);
    }

    @Watch('expanded')
    onExpandedChanged(
        newVal: CustomsShipmentItemViewModel[],
        oldVal: CustomsShipmentItemViewModel[]
    ) {
        this.currentShipmentDetail = null;

        if (newVal.length === 0) {
            this.currentHandlingItem = null;
        } else {
            this.currentHandlingItem = newVal[0];
            this.getShipmentDetail();
        }
    }

    onFilterSelected() {
        this.reloadShipments();
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }

    async getShipmentDetail() {
        this.isLoading = true;
        return CustomsExportController.getShipmentDetail(
            this.currentHandlingItem!.id!
        )
            .then((response) => {
                this.currentShipmentDetail = response.data;
            })
            .catch((error) => {
                this.displayError(
                    'Something went wrong while retrieving the detail data.'
                );
            })
            .finally(() => (this.isLoading = false));
    }

    async onShipmentDetailsUpdated() {
        this.isLoading = true;
        this.currentShipmentDetail = null;
        try {
            const response = await CustomsExportController.getShipmentDetail(
                this.currentHandlingItem!.id!
            );
            this.currentShipmentDetail = response.data;
        } catch {
            this.displayError(
                'Something went wrong while re-retrieving the shipment details'
            );
        }

        this.isLoading = false;
    }

    async readExcelLTS() {
        if (
            !(this.$refs.form as Vue & { validate: () => boolean }).validate()
        ) {
            return;
        }

        this.loadingReadExcelLts = true;
        var castedFile = this.uploadExcelLTSFile as File;
        try {
            var response = await this.api.readExcelLTS([castedFile]);

            var returnString = response.data;
            if (returnString) {
                alert(returnString);
            } else {
                this.saveSuccessful = true;
                this.uploadExcelLTSFile = null;
            }
        } catch {
            this.displayError(
                'Something went wrong while processing the LTS file.'
            );
        }
        this.loadingReadExcelLts = false;
    }

    async assignShipmentsToEmployee() {
        if (this.selectedEmployee == null) {
            this.noEmployeeSelected = true;
            return;
        }
        this.loadingAssignShipmentsToEmployee = true;

        try {
            await customsAdminApi.assignShipmentsToEmployee({
                employeeId: this.selectedEmployee.id,
                shipmentIds: this.selectedHandlingItems.map((c) => c.id!),
            });

            this.selectedHandlingItems = [];
            this.selectedEmployee = null;
            await this.loadShipments(this.options.page);
        } catch {
            this.displayError(
                'Something went wrong while assigning the shipments to the given employee.'
            );
        }

        this.loadingAssignShipmentsToEmployee = false;
    }

    async saveStatusToSelectedShipments() {
        this.loadingChangeStatusOfSelected = true;

        try {
            if (this.selectedStatus === null) {
                emitError(...['Selected status is null.']);
                return;
            }

            await customsAdminApi.setStatusOfShipments({
                shipmentIds: this.selectedHandlingItems.map((c) => c.id!),
                status: this.selectedStatus,
            });
            this.selectedHandlingItems = [];
            this.selectedStatus = null;
            await this.loadShipments(this.options.page);
        } catch {
            this.displayError(
                'Something went wrong while setting the status of the given shipments'
            );
        }

        this.loadingChangeStatusOfSelected = false;
    }

    async getEmployees() {
        this.employees = [];
        try {
            var response = await CustomsExportController.getEmployees();
            this.employees = response.data;
        } catch {
            this.displayError(
                'Something went wrong while retrieving the employees'
            );
        }
    }

    onShipmentStatusChanged({ success }: any) {
        if (success) {
            this.selectedHandlingItems = [];
            this.loadShipments(this.options.page);
        }
    }
}
