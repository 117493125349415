import { CustomsParcelApi, ShipmentDetailParcelViewModel } from '@/openapi';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { getSortBy, getSortDesc } from '@/helpers/dataTableSortHelper';

import { emitError } from '@/event-bus';
const parcelsApi = new CustomsParcelApi(undefined, '');

@Component({})
export default class CustomsParcels extends Vue {
    @Prop({ required: true })
    shipmentId!: number;

    @Prop({ default: '' })
    height!: string;

    items: ShipmentDetailParcelViewModel[] = [];
    totalAmountOfItems = 0;
    loading = false;

    options: DataOptions = {
        page: 1,
        itemsPerPage: 100,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
    };

    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [1, 5, 25, 50, 100],
        disablePagination: false,
    };

    headers = [
        { text: 'Parcelnumber', value: 'parcelNumber', width: '30%' },
        { text: 'Weight', value: 'weight' },
        { text: 'Dimensions', value: 'dimensions' }
    ];

    async created() {
        await this.getParcels();
    }

    async getParcels() {
        try {
            this.loading = true;
            const response = await parcelsApi.getParcels(
                this.shipmentId,
                getSortBy(this.options),
                getSortDesc(this.options),
                this.options.page,
                this.options.itemsPerPage
            );

            this.items = response.data.items ?? [];
            this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
        } catch {
            emitError(
                'Something went wrong while retrieving the parcels of the shipment.'
            );
        }

        this.loading = false;
    }

    onUpdateOptions(options: DataOptions) {
        this.getParcels();
    }
}
