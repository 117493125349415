import { Component, Vue, Watch } from 'vue-property-decorator';

import { EmployeeUserRightsViewModel } from '../api/viewmodels/EmployeeUserRightsViewModel';
import { getPagedEmployeeUserRights, saveEmployeeUserRights } from '../api/EmployeeUserRightsController';
import { ToolbarItem } from '../models/ToolbarItem';
import { ValidationResult } from 'vee-validate/dist/types/types';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { CustomerViewModel, DataApi } from '../openapi';



@Component({
})
export default class EmployeeUserRights extends Vue {
    dataApi: DataApi = new DataApi(undefined, '')

    headers = [
        { text: 'Last name', value: 'actions', sortable: false, width: '20em', class: "grey lighten-5" },
        { text: 'First name', value: 'firstName', class: "grey lighten-5" },
        { text: 'E-mail', value: 'email', class: "grey lighten-5" },
        { text: 'Login name', value: 'loginName', class: "grey lighten-5" },
        { text: 'Password', value: 'password', sortable: false, align: 'center', class: "grey lighten-5" },
        { text: 'Must change password', value: 'mustChangePassword', align: 'center', class: "grey lighten-5" },

        { text: '1st Level User', value: 'userRightsImportLevel1User', align: 'center', class: "lime lighten-5" },
        { text: '2nd Level User', value: 'userRightsImportLevel2User', align: 'center', class: "lime lighten-5" },
        { text: '3rd Level User', value: 'userRightsImportLevel3User', align: 'center', class: "lime lighten-5" },
        { text: 'DAP', value: 'userRightsImportDAP', align: 'center', class: "lime lighten-5" },
        { text: 'Business', value: 'userRightsImportBusiness', align: 'center', class: "lime lighten-5" },
        { text: 'See jobs from customer1', value: 'userRightsImportSeeJobsFromCustomer1', align: 'end', class: "lime lighten-5" },
        { text: 'See jobs from customer2', value: 'userRightsImportSeeJobsFromCustomer2', align: 'end', class: "lime lighten-5" },
        { text: 'See jobs from customer3', value: 'userRightsImportSeeJobsFromCustomer3', align: 'end', class: "lime lighten-5" },
        { text: 'Number of jobs', value: 'userRightsImportNumberOfJobs', align: 'end', class: "lime lighten-5" },

        { text: 'Check Modus', value: 'userRightsExportCheckModus', align: 'center', class: "cyan lighten-5" },
        { text: 'Hold/Return Modus', value: 'userRightsExportHoldReturnModus', align: 'center', class: "cyan lighten-5" },
        { text: 'Export Modus', value: 'userRightsExportExportModus', align: 'center', class: "cyan lighten-5" },
        { text: 'Transit Modus', value: 'userRightsExportTransitModus', align: 'center', class: "cyan lighten-5" },
        { text: 'Admin Modus', value: 'userRightsExportAdminModus', align: 'center', class: "cyan lighten-5" },
        { text: 'Admin Assign shipments', value: 'userRightsExportAdminModusAssignShipmentsTo', align: 'center', class: "cyan lighten-5" },
        { text: 'Admin Change status', value: 'userRightsExportAdminModusChangeStatusTo', align: 'center', class: "cyan lighten-5" },
        { text: 'Admin Upload LTS', value: 'userRightsExportAdminModusAllowToUploadLTSReports', align: 'center', class: "cyan lighten-5" },

        { text: 'Download Files', value: 'userRightsOperationalAddOnsDownloadFiles', align: 'center', class: "red lighten-5" },
        { text: 'DPD Consolidation', value: 'userRightsOperationalAddOnsDPDConsolidation', align: 'center', class: "red lighten-5" },
        { text: 'Flight Handling', value: 'userRightsOperationalAddOnsFlightHandling', align: 'center', class: "red lighten-5" },

        { text: 'Reminder System', value: 'userRightsFinancialReminderSystem', align: 'center', class: "brown lighten-5" },

        { text: 'Articles', value: 'userRightsMaintenanceArticles', align: 'center', class: "amber lighten-5" },
        { text: 'Export Flows', value: 'userRightsMaintenanceExportFlows', align: 'center', class: "amber lighten-5" },
        { text: 'Custom Office Customers', value: 'userRightsMaintenanceCustomOfficeCustomers', align: 'center', class: "amber lighten-5" },

        { text: 'Warehouse Locations', value: 'userRightsMaintenanceWarehouseLocations', align: 'center', class: "purple lighten-5" },
        { text: 'Employees', value: 'userRightsMaintenanceEmployees', align: 'center', class: "purple lighten-5" },
        { text: 'User Rights', value: 'userRightsMaintenanceUserRights', align: 'center', class: "purple lighten-5" },
        { text: 'Payment Reminder Settings', value: 'userRightsMaintenancePaymentReminderSettings', align: 'center', class: "purple lighten-5" },
        { text: 'Payment Reminder Emails', value: 'userRightsMaintenancePaymentReminderEmails', align: 'center', class: "purple lighten-5" },

        { text: 'Settings Import', value: 'userRightsSettingsImport', align: 'center', class: "teal lighten-4" },
        { text: 'Settings Export Transit', value: 'userRightsSettingsExportTransit', align: 'center', class: "teal lighten-4" },

        { text: 'Claim Category 1', value: 'userRightsClaimCategory1', align: 'center', class: "pink lighten-4" },
        { text: 'Claim Category 2', value: 'userRightsClaimCategory2', align: 'center', class: "pink lighten-4" },
        { text: 'Claim Category 3', value: 'userRightsClaimCategory3', align: 'center', class: "pink lighten-4" },
        { text: 'Claim Category 4', value: 'userRightsClaimCategory4', align: 'center', class: "pink lighten-4" },
    ];

    isLoading = false;
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [
            25,
            50,
            100,
            -1
        ],
        disablePagination: false
    }
    options: any = {
        page: 1,
        itemsPerPage: 100,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      };
    items: EmployeeUserRightsViewModel[] = [];
    totalItems: Number = 0;
    customers: CustomerViewModel[] = [];
    dirtyItem: boolean[] = [];

    timeoutDelay: any = null;
    disableNewItemButton: Boolean = false;

    created() {
        var toolbarButtons: ToolbarItem[] = [
            {
                callback: () => this.refresh(),
                icon: 'mdi-refresh',
                tooltipText: 'Refresh overview'
            }
        ];
        this.$emit('PageInfoReceived', 'Employee User Rights', toolbarButtons)

        this.getCustomers();

    }


    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.disableNewItemButton = false;
            this.loadItems(newVal.page, newVal.itemsPerPage, this.getSort(newVal.sortDesc), this.getSortField(newVal.sortBy));
        }, 250)
    }

    loadItems(page: number, itemsPerPage: number, orderByField: string, orderBy: string) {
        this.isLoading = true
        this.footerOptions.disablePagination = true;
        getPagedEmployeeUserRights(itemsPerPage, page, orderBy, orderByField)
        .then(result => {
            this.items = result.data.items;
            this.totalItems = result.data.totalItems;
        }).finally(() => {
            this.isLoading = false;
            this.footerOptions.disablePagination = false;
        });
    }

    async saveItem(item: EmployeeUserRightsViewModel) {
        if (this.isLoading) {
            return;
        }

        var validationObserver = (<Vue[]>this.$refs['observer-' + item.id])[0] as InstanceType<typeof ValidationObserver>;
        const promises: Promise<ValidationResult>[] = [];
        for (const key in this.$refs) {
            const split = key.split('-');
            if (!split.length || (key === 'observer-' + item.id)) {
                continue;
            }

            const propName = split[0];
            const id = parseInt(split[1]);
            if (id != item.id) {
                continue;
            }

            const validationProvider = (<Vue[]>this.$refs[key])[0] as InstanceType<typeof ValidationProvider>;
            promises.push(validationProvider.validate(item[propName as keyof EmployeeUserRightsViewModel]));
        }
        validationObserver.validate().then(async isValid => {
            const validationResults = await Promise.all(promises);
            let errorsArray: { id: string, errors: string[] }[] = [];
            Object.keys(validationObserver.errors).map(key => {
                if (validationObserver.errors[key].length > 0) {
                    errorsArray.push({ id: key, errors: validationObserver.errors[key] });
                }
            })
            for (var validationResult of validationResults) {
                if (!validationResult.valid) {
                    var errorObject = errorsArray.find(o => o.errors.indexOf(validationResult.errors[0]) !== -1);
                    if (errorObject) {
                        var errorElementId = errorObject?.id;
                        document.getElementById(errorElementId)?.scrollIntoView({ inline: 'start' });
                    }

                    return;
                }
            }

            this.isLoading = true;
            const isNewItem = this.isNewItem(item);
            const postData = JSON.parse(JSON.stringify(item));

            saveEmployeeUserRights(postData as EmployeeUserRightsViewModel)
                .then(result => {
                    if (!result?.data) {
                        return;
                    }

                    this.mapItem(item, result.data);
                    this.dirtyItem[item.id] = false;

                    if (isNewItem) {
                        this.disableNewItemButton = false;
                    }
                })
                .catch(error => {
                    this.$emit('errorOccured', [error.message]);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    isNewItem(item: EmployeeUserRightsViewModel) : Boolean {
        return item?.id === 0;
    }

    getSortField(sortFields: string[]) {
        return sortFields[0] ?? '';
    }

    getSort(sortDesc: Boolean[]) {
        let isDesc = sortDesc[0] ?? null;
        if(!(isDesc === false || isDesc === true)) {
            return '';
        }
        return isDesc ? 'DESC' : 'ASC';
    }

    mapItem(oldItem: any, newItem: any) {
        for (const key in oldItem) {
            oldItem[key] = newItem[key];
        }
    }

    getCustomers() {
        this.customers = [];

        this.dataApi.getCustomers(true).then(response => {
            this.customers = response.data;
        }).catch(error => {
            this.$emit('errorOccured', [error.message]);
        });
    }

    getCustomerName(id: number) {
        var customer = this.customers.find(c => c.id === id);
        if (!customer) {
            return '';
        }

        return customer.shortName;
    }

    generatePassword(item: EmployeeUserRightsViewModel) {
        item.password = Array.from(Array(8), () => Math.floor(Math.random() * 36).toString(36)).join('');
        this.setDirty(item.id);
    }

    setDirty(id: number) {
        this.dirtyItem[id] = true;
    }

    refresh() {
        this.loadItems(this.options.page, this.options.itemsPerPage, this.getSort(this.options.sortDesc), this.getSortField(this.options.sortBy));
        this.disableNewItemButton = false;
    }

}