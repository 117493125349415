import { Component, Vue, Watch } from 'vue-property-decorator';

import { EmployeeViewModel } from '../api/viewmodels/EmployeeViewModel';
import { getPagedEmployees, saveEmployee } from '../api/EmployeesController';
import { ToolbarItem } from '../models/ToolbarItem';
import { ValidationResult } from 'vee-validate/dist/types/types';
import { ValidationObserver, ValidationProvider } from 'vee-validate';


@Component({
})
export default class Employees extends Vue {
    headers = [
        {
            text: 'Employee ID',
            align: 'start',
            value: 'id',
        },
        { text: 'Name', value: 'name' },
        { text: 'Num Assigns', value: 'numberOfExportShipmentsToAssign' },
        { text: 'Actions', value: 'actions', sortable: false },
    ];
    isLoading = false;
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [
            25,
            50,
            100,
            -1
        ],
        disablePagination: false
    }
    options: any = {
        page: 1,
        itemsPerPage: 100,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      };
    items: EmployeeViewModel[] = [];
    totalItems: Number = 0;
    timeoutDelay: any = null;
    disableNewItemButton: Boolean = false;

    created() {
        var toolbarButtons: ToolbarItem[] = [
            {
                callback: () => this.refresh(),
                icon: 'mdi-refresh',
                tooltipText: 'Refresh overview'
            }
        ];
        this.$emit('PageInfoReceived', 'Employees', toolbarButtons)
    }

    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.disableNewItemButton = false;
            this.loadItems(newVal.page, newVal.itemsPerPage, this.getSort(newVal.sortDesc), this.getSortField(newVal.sortBy));
        }, 250)
    }

    loadItems(page: number, itemsPerPage: number, orderByField: string, orderBy: string) {
        this.isLoading = true
        this.footerOptions.disablePagination = true;
        getPagedEmployees(itemsPerPage, page, orderBy, orderByField)
        .then(result => {
            this.items = result.data.items;
            this.totalItems = result.data.totalItems;
        }).finally(() => {
            this.isLoading = false;
            this.footerOptions.disablePagination = false;
        });
    }

    async saveItem(item: EmployeeViewModel) {
        if (this.isLoading) {
            return;
        }

        var validationObserver = (<Vue[]>this.$refs['observer-' + item.id])[0] as InstanceType<typeof ValidationObserver>;
        const promises: Promise<ValidationResult>[] = [];
        for (const key in this.$refs) {
            const split = key.split('-');
            if (!split.length || (key === 'observer-' + item.id)) {
                continue;
            }

            const propName = split[0];
            const id = parseInt(split[1]);
            if (id != item.id) {
                continue;
            }

            const validationProvider = (<Vue[]>this.$refs[key])[0] as InstanceType<typeof ValidationProvider>;
            promises.push(validationProvider.validate(item[propName as keyof EmployeeViewModel]));
        }
        validationObserver.validate().then(async isValid => {
            const validationResults = await Promise.all(promises);
            let errorsArray: { id: string, errors: string[] }[] = [];
            Object.keys(validationObserver.errors).map(key => {
                if (validationObserver.errors[key].length > 0) {
                    errorsArray.push({ id: key, errors: validationObserver.errors[key] });
                }
            })
            for (var validationResult of validationResults) {
                if (!validationResult.valid) {
                    var errorObject = errorsArray.find(o => o.errors.indexOf(validationResult.errors[0]) !== -1);
                    if (errorObject) {
                        var errorElementId = errorObject?.id;
                        document.getElementById(errorElementId)?.scrollIntoView({ inline: 'start' });
                    }

                    return;
                }
            }

            this.isLoading = true;
            const isNewItem = this.isNewItem(item);
            const postData = JSON.parse(JSON.stringify(item));

            saveEmployee(postData as EmployeeViewModel)
                .then(result => {
                    if (!result?.data) {
                        return;
                    }

                    this.mapItem(item, result.data);
                    if (isNewItem) {
                        this.disableNewItemButton = false;
                    }
                })
                .catch(error => {
                    this.$emit('errorOccured', [error.message]);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    isNewItem(item: EmployeeViewModel) : Boolean {
        return item?.id === 0;
    }

    getSortField(sortFields: string[]) {
        return sortFields[0] ?? '';
    }

    getSort(sortDesc: Boolean[]) {
        let isDesc = sortDesc[0] ?? null;
        if(!(isDesc === false || isDesc === true)) {
            return '';
        }
        return isDesc ? 'DESC' : 'ASC';
    }

    mapItem(oldItem: any, newItem: any) {
        for (const key in oldItem) {
            oldItem[key] = newItem[key];
        }
    }

    refresh() {
        this.loadItems(this.options.page, this.options.itemsPerPage, this.getSort(this.options.sortDesc), this.getSortField(this.options.sortBy));
        this.disableNewItemButton = false;
    }

}