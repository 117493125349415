import { render, staticRenderFns } from "./DistributedImportJobsBacklog.vue?vue&type=template&id=1c33fd20&scoped=true"
import script from "./DistributedImportJobsBacklog.vue?vue&type=script&setup=true&lang=ts"
export * from "./DistributedImportJobsBacklog.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DistributedImportJobsBacklog.vue?vue&type=style&index=0&id=1c33fd20&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c33fd20",
  null
  
)

export default component.exports