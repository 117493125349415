<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @keyup.enter="searchArticles"
          ></v-text-field>
          <v-btn
            color="primary"
            class="ml-7 mr-2"
            :disabled="disableNewItemButton"
            @click="addNewItem"
          >
            New article
          </v-btn>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          fixed-footer
          fixed-header
          dense
          height="calc(100vh - 189px)"
          class="elevation-1"
          :loading="loading"
          :server-items-length="totalAmountOfItems"
          :options.sync="options"
          :footer-props="footerOptions"
          sort-by="wtn"
        >
          <template #item="{ item }">
            <ArticlePageArticleRow
              :key="item.id"
              :item="item"
              :measurement-units="dataStore.measurementUnits"
              :ac-document-types="dataStore.acDocumentTypes"
              :countries="dataStore.originCountries"
              :categories="articleCategories"
              @saveArticle="saveItem"
              @deleteArticle="deleteItem"
              @copyArticleTree="onArticleTreeCopy"
              @refresh="refresh"
              v-on="$listeners"
            ></ArticlePageArticleRow>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ToolbarItem } from "../models/ToolbarItem";
import ArticlePageArticleRow from "../components/ArticlePageArticleRow.vue";
import { onBeforeMount, ref, watch } from "vue";
import { useDataTableDefaults } from "@/composables/dataTable";
import { DataTableHeader } from "vuetify";
import { emitError, emitErrorWithFallback } from "@/event-bus";
import { useDataStore } from "@/stores/data-store";
import {
  ArticleCategoryDto,
  CustomsArticlesApi,
  CustomsArticleViewModel,
  DataApi,
} from "@/openapi";
import { useSorting } from "@/composables/sort";

const articleApi = new CustomsArticlesApi(undefined, "");
const dataApi = new DataApi(undefined, "");
const dataStore = useDataStore();
const emits = defineEmits(["PageInfoReceived"]);

const articleCategories = ref<ArticleCategoryDto[]>([]);

const headers = ref<DataTableHeader[]>([
  {
    text: "Description",
    align: "start",
    value: "description",
    width: "15%",
  },
  { text: "WTN", value: "wtn", width: "7%" },
  { text: "Variants", value: "Variants", width: "8%" },
  { text: "Warenbeschreibung", value: "goodsDescription", width: "10%" },
  { text: "Kategorie", value: "categories", width: "10%" },
  { text: "Sub-Kategorie", value: "subCategories", width: "10%" },
  {
    text: "Statistische maßeinheit / Statistical unit of measurement",
    value: "unitOfMeasurement",
    sortable: false,
    width: "7%",
  },
  {
    text: "Zollmenge Maßeinheit",
    value: "zollMengeUnitOfMeasurement",
    sortable: false,
    width: "7%",
  },
  { text: "Schlagwort", value: "keywords", width: "15%" },
  {
    text: "Don't use for Impost",
    value: "dontUseForImpost",
    sortable: false,
    width: "7%",
  },
  { text: "Actions", value: "actions", sortable: false, width: "7%" },
]);
const { options, footerOptions, items, totalAmountOfItems, loading } =
  useDataTableDefaults<CustomsArticleViewModel>();
options.value.itemsPerPage = 50;
options.value.sortBy = ["wtn"];
options.value.mustSort = true;

const { sortBy, sortDesc } = useSorting(options);

const search = ref("");
const disableNewItemButton = ref(false);

let timeoutDelay: number | undefined;
watch(
  () => options,
  (newVal, oldVal) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
      disableNewItemButton.value = false;
      loadItems(newVal.value.page);
    }, 250);
  },
  { deep: true },
);

onBeforeMount(async () => {
  const toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Articles", toolbarButtons);

  await Promise.all([
    dataStore.fetchAcDocumentTypes(),
    dataStore.fetchOriginCountries(),
    dataStore.fetchMeasurementUnits(),
    getArticleCategories(),
  ]);
});
const loadItems = async (page: number = 1) => {
  if (loading.value) {
    return;
  }
  loading.value = true;
  footerOptions.value.disablePagination = true;
  try {
    const result = await articleApi.getCustomsArticlesItems(
      sortBy.value,
      sortDesc.value,
      page,
      options.value.itemsPerPage,
      search.value,
    );
    items.value = result.data.items ?? [];
    totalAmountOfItems.value = result.data.totalItems ?? 0;
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while retrieving the articles",
    );
  } finally {
    loading.value = false;
    footerOptions.value.disablePagination = false;
  }
};

const saveItem = async (item: CustomsArticleViewModel) => {
  if (loading.value) {
    return;
  }

  item.needsSaving = false;

  const datatableItem = items.value.find((i) => i.id === item.id);
  loading.value = true;
  const postData = JSON.parse(
    JSON.stringify(item, (key, value) => {
      if (key === "parent") {
        return undefined;
      }

      return value;
    }),
  ) as CustomsArticleViewModel;
  const isNewItem = postData.id! < 1;
  postData.id = postData.id! < 1 ? 0 : postData.id;
  try {
    const result = await articleApi.saveCustomsArticleItem(postData);
    const newDatatableItem = result.data;

    if (datatableItem !== undefined) {
      const indexOfTempItem = items.value.indexOf(datatableItem);
      items.value.splice(indexOfTempItem, 1);
      items.value.unshift(newDatatableItem);
    } else {
      item = newDatatableItem;
    }

    if (isNewItem) {
      disableNewItemButton.value = false;
      refresh();
    }
  } catch {
    emitError("Something went wrong while saving the article");
  } finally {
    loading.value = false;
  }
};

const deleteItem = async (item: CustomsArticleViewModel) => {
  if (loading.value) {
    return;
  }
  const newItem = JSON.parse(
    JSON.stringify(item, (key, value) => {
      if (key === "parent") {
        return undefined;
      }

      return value;
    }),
  ) as CustomsArticleViewModel;

  loading.value = true;
  try {
    await articleApi.deleteCustomArticleItem(newItem);
  } catch {
    emitError("Something went wrong while deleting the article");
  } finally {
    loading.value = false;
    refresh();
  }
};

const addNewItem = () => {
  disableNewItemButton.value = true;

  const newItem = {} as CustomsArticleViewModel;
  newItem.id = Math.trunc(Math.random() * -100000);
  newItem.description = "<new root item>";
  newItem.goodsDescription = "<new root item>";
  newItem.needsSaving = false;
  items.value.unshift(newItem);
};

const refresh = async () => {
  await loadItems();
  disableNewItemButton.value = false;
};

const searchArticles = async () => {
  await loadItems();
  disableNewItemButton.value = false;
};

const onArticleTreeCopy = async (newItem: CustomsArticleViewModel) => {
  loading.value = true;
  try {
    await articleApi.duplicateCustomsArticle(newItem);
  } catch (e: unknown) {
    emitErrorWithFallback("Something went wrong while duplicating");
  } finally {
    loading.value = false;
    refresh();
  }
};

const getArticleCategories = async () => {
  try {
    const response = await dataApi.getArticleCategories();
    articleCategories.value = response.data;
  } catch {
    emitError("Something went wrong while retrieving the article categories");
  }
};
</script>
