import Vue, { DirectiveBinding, VNode } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './scss/app.scss';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import './plugins/axios';
import EditableField from './components/EditableField.vue.html';
import { Fragment } from 'vue-frag';
import { AccountApi } from './openapi';
import DatePicker from './components/editors/DatePicker.vue.html';
import DateTimePicker from './components/editors/DateTimePicker.vue';

import { createPinia, PiniaVuePlugin } from 'pinia';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('EditableField', EditableField);
Vue.component('Fragment', Fragment);
Vue.component('DatePicker', DatePicker);
Vue.component('DateTimePicker', DateTimePicker);

Vue.filter(
    'formatDate',
    function (value: Date | null, dateFormat: string | null) {
        dateFormat ??= 'DD-MM-YYYY HH:mm:ss';
        return value ? moment(value).format(dateFormat) : '';
    }
);

import { extend } from 'vee-validate';
import {
    required,
    required_if,
    min_value,
    max,
    numeric,
    email,
    regex,
    double,
    integer,
    digits,
} from 'vee-validate/dist/rules';
import moment from 'moment';
extend('required', required);
extend('required_if', required_if);

extend('min_value', min_value);
extend('max', max);
extend('numeric', numeric);
extend('email', email);
extend('regex', regex);
extend('double', double);
extend('integer', integer);
extend('digits', digits);
extend('requiredAllowEmpty', {
    validate(value) {
        return {
            required: true,
            valid: [null, undefined].indexOf(value) === -1,
        };
    },
    computesRequired: true,
});

extend('mrn', (value) => {
    const pattern = /^[0-9]{2}[A-Z]{2}[a-zA-Z0-9]{14}$/;
    return pattern.test(value) || 'Invalid MRN number';
});

extend('atbNumber', (value) => {
    const pattern = /^[a-zA-Z]{3}[0-9]{18}$/;
    return pattern.test(value) || 'Invalid ATB-number';
});

extend('atbPosition', (value) => {
    const pattern = /^[0-9]{3}$/;
    return pattern.test(value) || 'Invalid ATB position';
});

extend('percentage', {
    params: ['minAmountOfDecimals'],
    validate(value, { minAmountOfDecimals }: Record<string, any>) {
        if (isNaN(value) || isNaN(parseFloat(value))) {
            return false;
        }

        let parsedValue = parseFloat(value);
        if (parsedValue < 0 || parsedValue > 100) {
            return false;
        }

        let parsedMinAmountOfDecimals = 0;
        if (
            minAmountOfDecimals &&
            !isNaN(minAmountOfDecimals) &&
            !isNaN(parseInt(minAmountOfDecimals))
        ) {
            parsedMinAmountOfDecimals = parseInt(minAmountOfDecimals);
        }

        if (parsedMinAmountOfDecimals == 0) {
            let regex = new RegExp(`(\\d+)|(\\d+\.\\d+)`, 'g');
            if (!regex.test(value)) {
                return false;
            }
        } else {
            let regex = new RegExp(
                `\\d+\.\\d{${parsedMinAmountOfDecimals},}`,
                'g'
            );
            if (!regex.test(value)) {
                return false;
            }
        }

        return true;
    },
});

extend('dateRange', (value) => {
    return value?.length === 2 || 'No range of dates selected';
});

extend('dateRangeOrder', (value) => {
    return (
        (value?.length === 2 && value[0] <= value[1]) ||
        'The start date should be smaller/equal to the selected end date'
    );
});

extend('allowedFileExtensions', (value, values) => {
    var files = Array.isArray(value) ? value : [value];
    return files.every((file: File) => {
        const currentFileExtension = file.name.substring(
            file.name.lastIndexOf('.') + 1
        );
        return values.some(
            (fileExtension: string) =>
                currentFileExtension.toUpperCase() ===
                fileExtension.toUpperCase()
        );
    })
        ? true
        : `The file(s) needs to have one of the following extensions: ${values.join(
              ', '
          )}`;
});

extend('allowedMediaTypes', (value: File | File[], values) => {
    var files = Array.isArray(value) ? value : [value];
    return files.every((file: File) =>
        values.some((mediaType: string) => file.type === mediaType)
    )
        ? true
        : `The file(s) needs to have one of the following media types: ${values.join(
              ', '
          )}`;
});

extend('atLeastOneSelected', {
    validate: (value) => {
        return (
            (Array.isArray(value) && value.length > 0) ||
            'Select at least one item'
        );
    },
    computesRequired: true,
});

extend('isUnique', {
    validate: (editorValue: any[], args: any) => {
        const hasDuplicates = editorValue.some((value, index) => {
            const foundIndex = editorValue.findIndex((c) =>
                args.prop ? c[args.prop] == value[args.prop] : c == value
            );

            return foundIndex !== index;
        });
        return hasDuplicates ? "You've selected a duplicate value!" : true;
    },
    params: ['prop'],
});

extend('isGreaterThan', {
    validate(value: number | null, { target }: Record<string, any>) {
        if (value === null) {
            return true;
        }
        return value > (target ?? 0);
    },
    message: '{_field_} needs to be greater than {_target_}',
    params: ['target'],
});

extend('customsOfficeNumber', (value) => {
    const pattern = /^[A-Z]{2}\d{6}$/;
    return pattern.test(value) || 'Invalid value';
});

const refocusOnEnter = (event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    (event.target as HTMLElement).dataset.lastKey = keyboardEvent.key;
};

Vue.directive('refocusOnEnter', {
    bind(el) {
        const editor = el.querySelector<HTMLInputElement | HTMLTextAreaElement>(
            'input,select,textarea'
        );
        editor!.addEventListener('keypress', refocusOnEnter);
    },
    update(el: HTMLElement, binding: any, vnode: VNode, oldVnode: VNode) {
        const currentElement = el.querySelector<
            HTMLInputElement | HTMLTextAreaElement
        >('input,select,textarea');
        if (!currentElement || currentElement.dataset.lastKey !== 'Enter') {
            return;
        }

        if (document.activeElement === currentElement) {
            currentElement!.dataset.wasFocused = 'true';
        } else if (
            currentElement?.dataset.wasFocused === 'true' &&
            currentElement.disabled
        ) {
            currentElement.dataset.wasDisabled = 'true';
        } else if (
            currentElement?.dataset.wasFocused === 'true' &&
            currentElement.dataset.wasDisabled === 'true'
        ) {
            currentElement.focus();
            currentElement.dataset.wasFocused = 'false';
            currentElement.dataset.wasDisabled = 'false';
            currentElement.dataset.lastKey = '';
        } else {
            currentElement!.dataset.wasFocused = 'false';
            currentElement!.dataset.wasDisabled = 'false';
            currentElement!.dataset.lastKey = '';
        }
    },
    unbind(el: HTMLElement) {
        const editor = el.querySelector<HTMLInputElement | HTMLTextAreaElement>(
            'input,select,textarea'
        );
        editor!.removeEventListener('keypress', refocusOnEnter);
    },
});

Vue.config.productionTip = false;

const accountApi = new AccountApi(undefined, '');

accountApi
    .checkUserStillActive()
    .then((result) => {
        if (result.data.active) {
            store.commit('setLoggedIn');
            store.commit('setUserRights', result.data.userRights);
        } else {
            store.commit('setLoggedOut');
        }
    })
    .catch((error) => {})
    .finally(() => {
        new Vue({
            router,
            store,
            vuetify,
            pinia,
            render: (h) => h(App),
        }).$mount('#app');
    });
