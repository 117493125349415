<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <ValidationObserver v-slot="{ invalid }" ref="observer">
          <form>
            <v-data-table
              :headers="headers"
              :items="customsHandlingItems"
              :single-expand="true"
              :expanded.sync="expanded"
              item-key="hawb"
              height="calc(100vh - 123px)"
              fixed-header
              fixed-footer
              show-expand
              class="elevation-1"
              :loading="isLoading"
            >
              <template v-slot:item.customsDeadline="{ item }">
                {{ formatDateTime(item.customsDeadline) }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" v-if="currentHandlingItem">
                  <v-flex class="pa-3 pb-5" v-show="!fullView">
                    <LimitedCustomsView
                      :handlingItem.sync="currentHandlingItem"
                      :destinationCountries="destinationCountries"
                      :codes="codes"
                      :valuesCurrency="valuesCurrency"
                      @group-articles="groupArticles"
                      :getCodeText="getCodeText"
                      :getDestinationCountryText="getDestinationCountryText"
                      @fullView-changed="fullViewChanged"
                      @save="save"
                      @exportToDakosy="exportToDakosy"
                      :errorWithArticles="errorWithArticles"
                      :exchangeRateCurrencies="exchangeRateCurrencies"
                      :getRoundedTo2DigitsValue="getRoundedTo2DigitsValue"
                      @update-zollwert="onZollwertUpdateNeeded"
                      :updateArticlePrices.sync="updateArticlePrices"
                      :articleTypes="articleTypes"
                      :unitsOfMeasurement="unitsOfMeasurement"
                      :openEscalationModal="openEscalationModal"
                      :typeOfBusinesses="typeOfBusinesses"
                      :getTypeOfBusinessText="getTypeOfBusinessText"
                    ></LimitedCustomsView>
                  </v-flex>
                  <v-flex class="pa-3 pb-5" v-show="fullView">
                    <v-container fluid>
                      <v-row>
                        <v-col class="text-right">
                          <v-btn
                            v-if="currentHandlingItem.canBeEscalated"
                            color="error"
                            @click="openEscalationModal"
                            class="mr-3"
                          >
                            <v-icon color="white">mdi-exclamation-thick</v-icon>
                            Escalate
                          </v-btn>
                          <v-btn @click="fullView = false"
                            >Show limited overview</v-btn
                          >
                          <v-tooltip
                            top
                            v-if="currentHandlingItem.dakosyExport !== null"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="success"
                                class="ml-3"
                                v-bind="attrs"
                                v-on="on"
                                v-if="
                                  !currentHandlingItem.dakosyExport.hadError &&
                                  currentHandlingItem.dakosyExport.exported
                                "
                              >
                                <v-icon color="white">mdi-check</v-icon>
                                Create & send to dakosy
                              </v-btn>
                              <v-btn
                                color="error"
                                class="ml-3"
                                v-bind="attrs"
                                v-on="on"
                                v-else-if="
                                  currentHandlingItem.dakosyExport.hadError
                                "
                                @click="exportToDakosy"
                              >
                                <v-icon color="white"
                                  >mdi-exclamation-thick</v-icon
                                >
                                Create & send to dakosy
                              </v-btn>
                              <v-btn
                                color="info"
                                class="ml-3"
                                v-bind="attrs"
                                v-on="on"
                                v-else
                              >
                                <v-icon color="white"
                                  >mdi-clock-time-four-outline</v-icon
                                >
                                Create & send to dakosy
                              </v-btn>
                            </template>
                            <span>
                              <b>Export created:</b>
                              {{
                                !!currentHandlingItem.dakosyExport.creationDate
                                  ? formatDateTimeWithTime(
                                      currentHandlingItem.dakosyExport
                                        .creationDate,
                                    )
                                  : ""
                              }}<br />
                              <b>Exported yet?:</b>
                              {{ currentHandlingItem.dakosyExport.exported
                              }}<br />
                              <b>Export date:</b>
                              {{
                                !!currentHandlingItem.dakosyExport.requestDate
                                  ? formatDateTimeWithTime(
                                      currentHandlingItem.dakosyExport
                                        .requestDate,
                                    )
                                  : ""
                              }}<br />
                              <b>Had errors:</b>
                              {{ currentHandlingItem.dakosyExport.hadError
                              }}<br />
                              <b>Error message:</b>
                              {{ currentHandlingItem.dakosyExport.error }}<br />
                              <b>Employee:</b>
                              {{ currentHandlingItem.dakosyExport.employee
                              }}<br />
                            </span>
                          </v-tooltip>
                          <v-btn
                            v-else
                            color="primary"
                            class="ml-3"
                            @click="exportToDakosy"
                            ><v-icon color="error">mdi-close</v-icon> Create &
                            send to dakosy</v-btn
                          >
                          <v-btn color="primary" class="ml-3" @click="save"
                            >Save</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-card color="grey darken-3">
                            <v-card-title>Sendunginformationen</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="6">
                                  <ValidationProvider
                                    name="Verfahren"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-text-field
                                      hide-details
                                      dense
                                      label="Verfahren"
                                      :error-messages="errors"
                                      outlined
                                      v-model="currentHandlingItem.registration"
                                    ></v-text-field>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                  <ValidationProvider
                                    name="Zollrechtlicher status"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-text-field
                                      hide-details
                                      dense
                                      label="Zollrechtlicher status"
                                      :error-messages="errors"
                                      outlined
                                      v-model="
                                        currentHandlingItem.customsStatus
                                      "
                                    ></v-text-field>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    name="Gesch�fsart"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-select
                                      hide-details
                                      dense
                                      :items="typeOfBusinesses"
                                      item-value="id"
                                      :item-text="getTypeOfBusinessText"
                                      :error-messages="errors"
                                      outlined
                                      v-model="currentHandlingItem.businessType"
                                    >
                                      <template v-slot:label>
                                        Gesch&#xE4;fsart
                                      </template>
                                    </v-select>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                  <ValidationProvider
                                    name="Ursprungsland"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-select
                                      hide-details
                                      dense
                                      :items="originCountries"
                                      :item-text="getCountryOfOriginText"
                                      item-value="code"
                                      label="Ursprungsland"
                                      :error-messages="errors"
                                      outlined
                                      v-model="
                                        currentHandlingItem.countryOfOrigin
                                      "
                                    ></v-select>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                  <v-select
                                    hide-details
                                    dense
                                    :items="departureAirports"
                                    item-value="code"
                                    :item-text="getDepartureAirportText"
                                    label="Abflughafen"
                                    outlined
                                    v-model="
                                      currentHandlingItem.departureAirport
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    name="Frachtkosten %"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-text-field
                                      hide-details
                                      dense
                                      label="Frachtkosten %"
                                      outlined
                                      :error-messages="errors"
                                      type="number"
                                      min="0"
                                      max="100"
                                      v-model="
                                        currentHandlingItem.freightcostPercentage
                                      "
                                    ></v-text-field>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                  <v-select
                                    hide-details
                                    dense
                                    :items="euDestinationCountries"
                                    :item-text="getDestinationCountryText"
                                    item-value="id"
                                    label="Bestimmungs EU land"
                                    outlined
                                    v-model="
                                      currentHandlingItem.destinationEUCountry
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col cols="6">
                                  <ValidationProvider
                                    name="Bestimmungsbundesland"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-select
                                      hide-details
                                      dense
                                      :items="destinationCountries"
                                      :item-text="getDestinationCountryText"
                                      item-value="id"
                                      label="Bestimmungsbundesland"
                                      :error-messages="errors"
                                      outlined
                                      v-model="
                                        currentHandlingItem.destinationCountry
                                      "
                                    ></v-select>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                  <ValidationProvider
                                    name="Lieferbedingung"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-select
                                      hide-details
                                      dense
                                      :items="deliveryConditions"
                                      item-value="code"
                                      item-text="code"
                                      label="Lieferbedingung"
                                      :error-messages="errors"
                                      outlined
                                      v-model="
                                        currentHandlingItem.deliveryCondition
                                      "
                                    ></v-select>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                  <ValidationProvider
                                    name="Lieferbedingungen Ort"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-text-field
                                      hide-details
                                      dense
                                      label="Lieferbedingungen Ort"
                                      :error-messages="errors"
                                      outlined
                                      v-model="
                                        currentHandlingItem.deliveryConditionLocation
                                      "
                                    ></v-text-field>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    name="Statistischer status"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-select
                                      hide-details
                                      dense
                                      :items="statisticalStatusses"
                                      label="Statistischer status"
                                      :error-messages="errors"
                                      outlined
                                      v-model="
                                        currentHandlingItem.statisticalStatus
                                      "
                                    ></v-select>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                  <v-checkbox
                                    hide-details
                                    label="Vorsteuerabzung"
                                    v-model="
                                      currentHandlingItem.preTaxDeduction
                                    "
                                  >
                                    <template v-slot:label>
                                      Vorsteuerabzung
                                    </template>
                                  </v-checkbox>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    name="Verkehrsmittel"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-select
                                      hide-details
                                      dense
                                      :items="transportTypes"
                                      item-value="id"
                                      :item-text="getTransportTypeText"
                                      label="Verkehrsmittel"
                                      :error-messages="errors"
                                      outlined
                                      v-model="
                                        currentHandlingItem.transportType
                                      "
                                    ></v-select>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    name="Code"
                                    rules="requiredAllowEmpty"
                                    v-slot="{ errors }"
                                  >
                                    <v-select
                                      hide-details
                                      dense
                                      :items="codes"
                                      :item-text="getCodeText"
                                      item-value="id"
                                      label="Code"
                                      :error-messages="errors"
                                      outlined
                                      v-model="
                                        currentHandlingItem.customsCodeId
                                      "
                                    ></v-select>
                                    <span>{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea
                                    hide-details
                                    dense
                                    outlined
                                    name="input-7-4"
                                    label="Positionszusatz"
                                    rows="2"
                                    v-model="currentHandlingItem.additionalText"
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="8">
                          <v-row>
                            <v-col cols="6">
                              <v-card color="lighten-3">
                                <v-card-title>Invoice info</v-card-title>
                                <v-card-text>
                                  <v-row>
                                    <v-col
                                      v-if="!$store.getters.isBpoUser"
                                      cols="12"
                                    >
                                      <v-text-field
                                        hide-details
                                        dense
                                        label="Name"
                                        outlined
                                        v-model="
                                          currentHandlingItem.invoiceName
                                        "
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-text-field
                                        hide-details
                                        dense
                                        label="Address"
                                        outlined
                                        v-model="
                                          currentHandlingItem.invoiceAddress
                                        "
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-row>
                                        <v-col cols="4">
                                          <v-text-field
                                            hide-details
                                            dense
                                            label="Zipcode"
                                            outlined
                                            v-model="
                                              currentHandlingItem.invoiceZipcode
                                            "
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="8">
                                          <v-text-field
                                            hide-details
                                            dense
                                            label="City"
                                            outlined
                                            v-model="
                                              currentHandlingItem.invoiceCity
                                            "
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        hide-details
                                        dense
                                        :items="originCountries"
                                        :item-text="getCountryOfOriginText"
                                        item-value="code"
                                        label="Country"
                                        outlined
                                        v-model="
                                          currentHandlingItem.invoiceCountry
                                        "
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-row>
                                        <v-col cols="6">
                                          <v-text-field
                                            hide-details
                                            dense
                                            label="EORI number"
                                            outlined
                                            v-model="
                                              currentHandlingItem.invoiceEORINumber
                                            "
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                          <v-text-field
                                            hide-details
                                            dense
                                            label="Niederlassungsnummer"
                                            outlined
                                            v-model="
                                              currentHandlingItem.invoiceNLNr
                                            "
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="6">
                              <v-card color="grey darken-3">
                                <v-card-title>Gewichte / Preise</v-card-title>
                                <v-card-text>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-row>
                                        <v-col cols="6">
                                          <v-text-field
                                            hide-details
                                            dense
                                            label="Rechnungspreis"
                                            disabled
                                            outlined
                                            :value="rechnungsPreis"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                          <v-text-field
                                            hide-details
                                            dense
                                            disabled
                                            label="Currency"
                                            outlined
                                            value="EUR"
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="6">
                                          <ValidationProvider
                                            name="Frachtkosten"
                                            rules="required|min_value:0.01"
                                            v-slot="{ errors }"
                                          >
                                            <v-text-field
                                              hide-details
                                              dense
                                              label="Frachtkosten"
                                              :error-messages="errors"
                                              type="number"
                                              min="0"
                                              step="0.01"
                                              outlined
                                              v-model="
                                                currentHandlingItem.autoClearanceFreightCosts
                                              "
                                            ></v-text-field>
                                            <span>{{ errors[0] }}</span>
                                          </ValidationProvider>
                                        </v-col>
                                        <v-col cols="6">
                                          <ValidationProvider
                                            name="Frachtkosten currency"
                                            rules="required"
                                            v-slot="{ errors }"
                                          >
                                            <v-select
                                              hide-details
                                              dense
                                              :items="valuesCurrency"
                                              item-text="name"
                                              item-value="name"
                                              label="Currency"
                                              :error-messages="errors"
                                              outlined
                                              v-model="
                                                currentHandlingItem.autoClearanceFreightCostsCurrency
                                              "
                                            ></v-select>
                                            <span>{{ errors[0] }}</span>
                                          </ValidationProvider>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="6">
                                          <v-text-field
                                            hide-details
                                            dense
                                            label="Zollwert (EUR)"
                                            disabled
                                            outlined
                                            :value="
                                              currentHandlingItem.zollwert?.toFixed(
                                                2,
                                              )
                                            "
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-checkbox
                                        class="mt-0"
                                        hide-details
                                        disabled
                                        label="Frachtkosten auf alle Positionen aufteilen"
                                        v-model="
                                          currentHandlingItem.splitFreightCostOverAllPositions
                                        "
                                      ></v-checkbox>
                                      <v-checkbox
                                        hide-details
                                        label="Bank transfer"
                                        disabled
                                        v-model="
                                          currentHandlingItem.isBankTransfer
                                        "
                                      ></v-checkbox>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6">
                              <v-card color="grey darken-4">
                                <v-card-title>Automatic clearance</v-card-title>
                                <v-card-text>
                                  <v-row>
                                    <v-col cols="6">
                                      <v-textarea
                                        dense
                                        outlined
                                        name="input-7-4"
                                        label="Content"
                                        rows="2"
                                        v-model="currentHandlingItem.content"
                                      ></v-textarea>
                                      <v-textarea
                                        dense
                                        outlined
                                        name="input-7-4"
                                        label="Article condition"
                                        rows="2"
                                        v-model="
                                          currentHandlingItem.articleCondition
                                        "
                                      ></v-textarea>
                                      <v-textarea
                                        dense
                                        outlined
                                        name="input-7-4"
                                        label="Reason for import"
                                        rows="2"
                                        v-model="
                                          currentHandlingItem.reasonForImport
                                        "
                                      ></v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-row>
                                        <v-col cols="7">
                                          <v-text-field
                                            dense
                                            hide-details
                                            label="value of goods"
                                            type="number"
                                            min="0"
                                            step="0.01"
                                            outlined
                                            v-model="
                                              currentHandlingItem.valueOfGoods
                                            "
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="5">
                                          <v-select
                                            dense
                                            hide-details
                                            :items="valuesCurrency"
                                            item-text="name"
                                            item-value="name"
                                            label="Currency"
                                            outlined
                                            v-model="
                                              currentHandlingItem.valueOfGoodsCurrency
                                            "
                                          ></v-select>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="7">
                                          <v-text-field
                                            dense
                                            hide-details
                                            label="freight cost"
                                            type="number"
                                            min="0"
                                            step="0.01"
                                            outlined
                                            v-model="
                                              currentHandlingItem.freightCosts
                                            "
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="5">
                                          <v-select
                                            dense
                                            hide-details
                                            :items="valuesCurrency"
                                            item-text="name"
                                            item-value="name"
                                            label="Currency"
                                            outlined
                                            v-model="
                                              currentHandlingItem.freightCostsCurrency
                                            "
                                          ></v-select>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="7">
                                          <v-text-field
                                            dense
                                            hide-details
                                            label="Payment value"
                                            type="number"
                                            min="0"
                                            step="0.01"
                                            outlined
                                            v-model="
                                              currentHandlingItem.paymentValue
                                            "
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="5">
                                          <v-select
                                            dense
                                            hide-details
                                            :items="valuesCurrency"
                                            item-text="name"
                                            item-value="name"
                                            label="Currency"
                                            outlined
                                            v-model="
                                              currentHandlingItem.paymentValueCurrency
                                            "
                                          ></v-select>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="6">
                                          <v-text-field
                                            dense
                                            hide-details
                                            label="pin code"
                                            outlined
                                            v-model="
                                              currentHandlingItem.pincode
                                            "
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                          <v-select
                                            dense
                                            hide-details
                                            label="Color"
                                            :items="colors"
                                            item-value="id"
                                            item-text="description"
                                            outlined
                                            v-model="currentHandlingItem.color"
                                          ></v-select>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-textarea
                                        dense
                                        hide-details
                                        outlined
                                        name="input-7-4"
                                        label="Customer remarks"
                                        rows="2"
                                        v-model="
                                          currentHandlingItem.customerRemarks
                                        "
                                      ></v-textarea>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="6">
                              <v-card>
                                <v-toolbar class="elevation-0" dense>
                                  <v-toolbar-title>Documents</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon @click="addDocument">
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </v-toolbar>
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="text-left">Doc.Code</th>
                                        <th class="text-left">Description</th>
                                        <th class="text-left">Number</th>
                                        <th class="text-left">Date</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <template
                                        v-if="
                                          currentHandlingItem.acDocuments
                                            ?.length
                                        "
                                      >
                                        <tr
                                          v-for="document in currentHandlingItem.acDocuments"
                                        >
                                          <td>
                                            {{ document.documentCode }}
                                          </td>
                                          <td>
                                            {{ document.description }}
                                          </td>
                                          <td>
                                            {{ document.number }}
                                          </td>
                                          <td>
                                            {{
                                              !!document.date
                                                ? formatDateTime(document.date)
                                                : ""
                                            }}
                                          </td>
                                          <td>
                                            <v-btn
                                              icon
                                              @click="editDocument(document)"
                                            >
                                              <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn
                                              icon
                                              @click="deleteDocument(document)"
                                            >
                                              <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                          </td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                              <v-card class="mt-5">
                                <v-card-title>DPD</v-card-title>
                                <v-card-text>
                                  <v-row>
                                    <v-col cols="6">
                                      <v-text-field
                                        hide-details
                                        dense
                                        label="Value"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        outlined
                                        v-model="currentHandlingItem.dpdValue"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field
                                        hide-details
                                        dense
                                        label="Invoice number"
                                        outlined
                                        v-model="
                                          currentHandlingItem.dpdInvoiceNumber
                                        "
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="6">
                                      <v-text-field
                                        hide-details
                                        dense
                                        label="Customs transport cost"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        outlined
                                        v-model="
                                          currentHandlingItem.customerTransportCost
                                        "
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-select
                                        dense
                                        hide-details
                                        :items="valuesCurrency"
                                        item-text="name"
                                        item-value="name"
                                        label="Currency transport cost"
                                        outlined
                                        v-model="
                                          currentHandlingItem.customerTransportCostCurrency
                                        "
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12"> </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="8">
                          <articles
                            :articles.sync="currentHandlingItem.articles"
                            :valuesCurrency="valuesCurrency"
                            @group-articles="groupArticles"
                            :errorWithArticles="errorWithArticles"
                            :exchangeRateCurrencies="exchangeRateCurrencies"
                            :frachtkostenPercentage="
                              currentHandlingItem.freightcostPercentage
                            "
                            :getRoundedTo2DigitsValue="getRoundedTo2DigitsValue"
                            @update-zollwert="onZollwertUpdateNeeded"
                            :updateArticlePrices.sync="updateArticlePrices"
                            :articleTypes="articleTypes"
                            :unitsOfMeasurement="unitsOfMeasurement"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-card>
                            <v-card-title>Shipment information</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    dense
                                    hide-details
                                    label="Pieces"
                                    type="number"
                                    min="0"
                                    outlined
                                    v-model="currentHandlingItem.pieces"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                  <v-text-field
                                    dense
                                    hide-details
                                    label="Real weight"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    outlined
                                    v-model="currentHandlingItem.grossWeight"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-row>
                                    <v-col cols="6">
                                      <v-text-field
                                        dense
                                        hide-details
                                        label="Value"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        outlined
                                        v-model="currentHandlingItem.value"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-select
                                        dense
                                        hide-details
                                        :items="valuesCurrency"
                                        item-text="name"
                                        item-value="name"
                                        label="Currency"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-select
                                    dense
                                    hide-details
                                    :items="incoterms"
                                    item-text="name"
                                    item-value="id"
                                    label="Incoterms"
                                    outlined
                                    v-model="currentHandlingItem.incoterm"
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </td>
              </template>
            </v-data-table>
          </form>
        </ValidationObserver>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="saveSuccessful"
      timeout="4000"
      top
      right
      color="success"
    >
      Saved successful
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="saveSuccessful = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="dakosyExportFailed"
      timeout="8000"
      top
      right
      color="error"
      multi-line
    >
      Could not export to Dakosy, <br />please check if all required fields are
      filled in
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="dakosyExportFailed = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="dakosyExportFailed" timeout="8000" color="error">
      <ul>
        <li v-for="error in dakosyExportFailedErrors">{{ error }}</li>
      </ul>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="dakosyExportFailed = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="showDakosyExportWarning" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5"> Warning </v-card-title>
        <v-card-text>{{ dakosyExportWarningMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showDakosyExportWarning = false"> Close </v-btn>
          <v-btn
            v-if="allowDakosyExportContinueOnWarning"
            text
            @click="exportToDakosy()"
          >
            Export anyway
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEscalationModal" persistent max-width="600">
      <ValidationObserver v-slot="{ invalid }" ref="escalationModalObserver">
        <v-card>
          <v-card-title class="text-h5"> Escalate to next level </v-card-title>
          <v-card-text>
            <ValidationProvider
              name="Category"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                v-model="escalationCategory"
                :items="escalationCategories"
                :error-messages="errors"
                label="Category"
              >
              </v-select>
            </ValidationProvider>
            <ValidationProvider
              name="Remarks"
              rules="required"
              v-slot="{ errors }"
            >
              <v-textarea
                label="Remarks"
                v-model="escalationRemarks"
                counter
                :error-messages="errors"
                maxlength="1000"
              ></v-textarea>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showEscalationModal = false"> Close </v-btn>
            <v-btn
              text
              :loading="sendingEscalation"
              @click="escalateToNextLevel()"
            >
              Escalate
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
    <v-dialog
      v-if="showEscalationSnackbar && currentHandlingItem"
      v-model="showEscalationSnackbar"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5"> Escalated shipment </v-card-title>
        <v-card-text>
          <v-alert outlined type="warning" text>
            This shipment was escalated from a lower user level.<br />
            The remarks provided are: <br /><br />
            {{ currentHandlingItem.escalationRemarks }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              closeDialogCheck(() => {
                showEscalationSnackbar = false;
              })
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showShipmentHasAFailedDakosyExportModal && currentHandlingItem"
      v-model="showShipmentHasAFailedDakosyExportModal"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5"> Failed Dakosy export </v-card-title>
        <v-card-text>
          <v-alert outlined type="error" text>
            This shipment has a failed Dakosy export. Please fix the issue and
            press the "Create & Send to Dakosy" button again.<br /><br />
            The reason for the failure was:<br />
            {{ currentHandlingItem.dakosyExport.error }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              closeDialogCheck(() => {
                showShipmentHasAFailedDakosyExportModal = false;
              })
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showAddDocumentDialog && currentAcDocument"
      v-model="showAddDocumentDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5" v-if="!currentAcDocument?.id">
          Add document
        </v-card-title>
        <v-card-title class="text-h5" v-else> Edit document </v-card-title>
        <v-card-text>
          <v-select
            v-model="currentAcDocument.documentCode"
            :items="acDocumentTypes"
            item-text="code"
            item-value="code"
            label="Code"
            single-line
          ></v-select>
          <v-text-field
            v-model="currentAcDocument.description"
            label="Description"
            single-line
          ></v-text-field>
          <v-text-field
            v-model="currentAcDocument.number"
            label="Number"
            single-line
          ></v-text-field>
          <v-menu
            v-model="showDocumentDatePicker"
            :close-on-content-click="true"
            :nudge-left="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="documentDateModel"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="currentAcDocumentDate"
              @input="setDocumentDate"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="cancelSavingDocument"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveDocument"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showConfirmDeleteAcDocumentDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5"> Delete document </v-card-title>
        <v-card-text>
          <v-alert outlined type="error" text>
            Are you sure you want to remove the document?
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showConfirmDeleteAcDocumentDialog = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteDocumentConfirm"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showNotAllArticlesHaveTheirInfoSet"
      v-model="showNotAllArticlesHaveTheirInfoSet"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Not all articles have their info set
        </v-card-title>
        <v-card-text>
          Not all articles have their info set. Please fill in the information
          for each article using the "Group articles" or "Set article info"
          buttons in the Grouped Articles tab below.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showNotAllArticlesHaveTheirInfoSet = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showValuesAreDifferentDialog && currentHandlingItem"
      v-model="showValuesAreDifferentDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5"> Values are different </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              The values in the shipment are different. Please choose which
              value should be taken.
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              The value provided by the customer:<br />
              {{ currentHandlingItem.valueOfGoodsCurrency }}
              {{ currentHandlingItem.valueOfGoods }}
            </v-col>
            <v-col>
              The value in the shipment:<br />
              {{ currentHandlingItem.valueCurrency }}
              {{ currentHandlingItem.value }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              >Currently {{ currentHandlingItem.valueCurrency }}
              {{ currentHandlingItem.value }} will be used for the Dakosy
              export</v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row no-gutters>
              <v-col>
                <v-btn
                  width="220"
                  color="primary"
                  @click="
                    closeDialogCheck(() => {
                      takeValueFromCustomer();
                    })
                  "
                >
                  Take value from customer
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  width="220"
                  color="primary"
                  @click="
                    closeDialogCheck(() => {
                      takeValueFromShipment();
                    })
                  "
                >
                  Take value from shipment
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-btn
                  block
                  color="default"
                  @click="
                    closeDialogCheck(() => {
                      showValuesAreDifferentDialog = false;
                    })
                  "
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showFreightcostsAreDifferentDialog && currentHandlingItem"
      v-model="showFreightcostsAreDifferentDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Freight costs are different
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              The freight costs in the shipment are different. Please choose
              which value should be taken.
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              The freight costs provided by the customer:<br />
              {{ currentHandlingItem.freightCostsCurrency }}
              {{ currentHandlingItem.freightCosts }}
            </v-col>
            <v-col>
              The freightcosts in the shipment:<br />
              {{ currentHandlingItem.autoClearanceFreightCostsCurrency }}
              {{ currentHandlingItem.autoClearanceFreightCosts }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              >Currently
              {{ currentHandlingItem.autoClearanceFreightCostsCurrency }}
              {{ currentHandlingItem.autoClearanceFreightCosts }}
              will be used for the Dakosy export</v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row no-gutters>
              <v-col>
                <v-btn
                  width="220"
                  color="primary"
                  @click="
                    closeDialogCheck(() => {
                      takeFreightcostsFromCustomer();
                    })
                  "
                >
                  Take freight costs from customer
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  width="220"
                  color="primary"
                  @click="
                    closeDialogCheck(() => {
                      takeFreightcostsFromShipment();
                    })
                  "
                >
                  Take freight costs from shipment
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-btn
                  block
                  color="default"
                  @click="
                    closeDialogCheck(() => {
                      showFreightcostsAreDifferentDialog = false;
                    })
                  "
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RequestJobsDialog
      v-if="showRequestJobsDialog"
      v-model="showRequestJobsDialog"
      @confirm="onRequestJobsConfirm"
    ></RequestJobsDialog>
  </v-container>
</template>

<script setup lang="ts">
import * as CustomsController from "../api/CustomsController";
import moment from "moment";
import Articles from "@/components/Articles.vue.html";
import { GroupArticlesViewModel } from "../api/viewmodels/GroupArticlesViewModel";
import LimitedCustomsView from "@/components/LimitedCustomsView.vue.html";
import { ValidationObserver } from "vee-validate";
import { ToolbarItem } from "../models/ToolbarItem";
import {
  AcDocumentTypeViewModel,
  ArticleTypeViewModel,
  CodeViewModel,
  ColorViewModel,
  CountryOfOriginViewModel,
  CurrencyViewModel,
  DataApi,
  DeliveryConditionViewModel,
  DepartureAirportViewModel,
  DestinationCountryViewModel,
  IncotermViewModel,
  TransportTypeViewModel,
  TypeOfBusinessViewModel,
  UnitOfMeasurementViewModel,
} from "../openapi";
import { CustomsHandlingItemViewModel } from "../api/viewmodels/CustomsHandlingItemViewModel";
import { CustomsAcDocumentViewModel } from "../api/viewmodels/CustomsAcDocumentViewModel";
import { EscalateToNextLevelViewModel } from "../api/viewmodels/EscalateToNextLevelViewModel";
import { AccountApi } from "@/openapi";
import { emitError } from "@/event-bus";
import RequestJobsDialog from "@/components/dialogs/RequestJobsDialog.vue";
import { computed, onBeforeMount, ref, watch } from "vue";
import { DefaultDataTableItem } from "@/types/types";
import { DataTableHeader } from "vuetify";
import store from "../store";

class DakosyExportWarningOptions {
  allowReceiverTypeBAndNopreTaxDeduction?: boolean = false;
  allowReceiverTypePAndPreTaxDeduction?: boolean = false;
  allowZollwertLessThan150AndNotC07Code?: boolean = false;
  allowZollwertMoreThan150AndNotOhneCode?: boolean = false;
}

const accountApi = new AccountApi(undefined, "");
const dataApi = new DataApi(undefined, "");

const emits = defineEmits(["PageInfoReceived"]);

const requestNewJobToolbarItem = ref<ToolbarItem>({
  callback: () => {
    showRequestJobsDialog.value = true;
  },
  icon: "mdi-playlist-plus",
  tooltipText: "Request new jobs",
  disabled: false,
});

const headers = ref<DataTableHeader[]>([
  {
    text: "Status",
    align: "start",
    value: "status",
  },
  { text: "MAWB", value: "mawb" },
  { text: "HAWB", value: "hawb" },
  { text: "Origin Office", value: "originOffice" },
  { text: "Shipper name", value: "shipperName" },
  { text: "Gross Weight", value: "grossWeight" },
  { text: "Net Weight", value: "netWeight" },
  { text: "Pieces", value: "pieces" },
  { text: "Value", value: "value" },
  { text: "Incoterm", value: "incoterm" },
  { text: "Customs deadline", value: "customsDeadline" },
]);

if (!store.getters.isBpoUser) {
  headers.value.splice(5, 0, {
    text: "Consignee contact",
    value: "consigneeContact",
  });
}

const INCOTERM_DAP = 6;
const INCOTERM_DDP = 8;

const statisticalStatusses = ref<string[]>(["01", "03", "04"]);
const escalationCategories = ref<DefaultDataTableItem<number>[]>([
  {
    value: 1,
    text: "Artikel fehlt",
  },
  {
    value: 2,
    text: "EORI-Nummer fehlt",
  },
  {
    value: 5,
    text: "Name/Adresse fehlt oder inkorekt",
  },
  {
    value: 3,
    text: "Technischer Fehler",
  },
  {
    value: 4,
    text: "Sonstiges",
  },
]);

const saveSuccessful = ref(false);
const dakosyExportFailed = ref(false);
const dakosyExportFailedErrors = ref<string[]>([]);
const showDakosyExportWarning = ref(false);
const allowDakosyExportOptions = ref<DakosyExportWarningOptions>({});
const dakosyExportWarningMessage = ref("");
const allowDakosyExportContinueOnWarning = ref(false);
const fullView = ref(false);
const expanded = ref<CustomsHandlingItemViewModel[]>([]);

const showShipmentHasAFailedDakosyExportModal = ref(false);

const errorWithArticles = ref(false);
const updateArticlePrices = ref(false);

const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);
const escalationModalObserver = ref<InstanceType<
  typeof ValidationObserver
> | null>(null);

const isLoading = ref(false);

const showEscalationModal = ref(false);
const escalationRemarks = ref("");
const escalationCategory = ref<number | null>(null);

const sendingEscalation = ref(false);
const showEscalationSnackbar = ref(false);

const showRequestJobsDialog = ref(false);

const currentHandlingItem = ref<CustomsHandlingItemViewModel | null>(null);
const customsHandlingItems = ref<CustomsHandlingItemViewModel[]>([]);

const destinationCountries = ref<DestinationCountryViewModel[]>([]);
const codes = ref<CodeViewModel[]>([]);
const valuesCurrency = ref<CurrencyViewModel[]>([]);
const incoterms = ref<IncotermViewModel[]>([]);
const euDestinationCountries = ref<DestinationCountryViewModel[]>([]);
const typeOfBusinesses = ref<TypeOfBusinessViewModel[]>([]);
const originCountries = ref<CountryOfOriginViewModel[]>([]);
const departureAirports = ref<DepartureAirportViewModel[]>([]);
const deliveryConditions = ref<DeliveryConditionViewModel[]>([]);
const transportTypes = ref<TransportTypeViewModel[]>([]);
const colors = ref<ColorViewModel[]>([]);
const exchangeRateCurrencies = ref<CurrencyViewModel[]>([]);
const articleTypes = ref<ArticleTypeViewModel[]>([]);
const unitsOfMeasurement = ref<UnitOfMeasurementViewModel[]>([]);
const acDocumentTypes = ref<AcDocumentTypeViewModel[]>([]);

const showAddDocumentDialog = ref(false);
const showConfirmDeleteAcDocumentDialog = ref(false);
const showDocumentDatePicker = ref(false);
const documentDateModel = ref("");
const currentAcDocument = ref<CustomsAcDocumentViewModel | null>();
const skipAcDocumentCodeChangedWatch = ref(true);

const showNotAllArticlesHaveTheirInfoSet = ref(false);

const showValuesAreDifferentDialog = ref(false);
const showFreightcostsAreDifferentDialog = ref(false);

const shipmentOpenedDialogChecks: Function[] = [
  (currentHandlingItem: CustomsHandlingItemViewModel) => {
    showEscalationSnackbar.value = currentHandlingItem.isEscalated;
    return showEscalationSnackbar.value;
  },
  (currentHandlingItem: CustomsHandlingItemViewModel) => {
    showShipmentHasAFailedDakosyExportModal.value =
      currentHandlingItem.dakosyExport !== undefined &&
      currentHandlingItem.dakosyExport !== null
        ? currentHandlingItem.dakosyExport.hadError
        : false;
    return showShipmentHasAFailedDakosyExportModal.value;
  },
  (currentHandlingItem: CustomsHandlingItemViewModel) => {
    showValuesAreDifferentDialog.value =
      currentHandlingItem.incoterm === INCOTERM_DAP &&
      (currentHandlingItem.valueOfGoods !== currentHandlingItem.value ||
        currentHandlingItem.valueOfGoodsCurrency !==
          currentHandlingItem.valueCurrency);
    return showValuesAreDifferentDialog.value;
  },
  (currentHandlingItem: CustomsHandlingItemViewModel) => {
    showFreightcostsAreDifferentDialog.value =
      currentHandlingItem.incoterm === INCOTERM_DAP &&
      (currentHandlingItem.freightCosts !==
        currentHandlingItem.autoClearanceFreightCosts ||
        currentHandlingItem.freightCostsCurrency !==
          currentHandlingItem.autoClearanceFreightCostsCurrency);
    return showFreightcostsAreDifferentDialog.value;
  },
];
const currentOpenDialogCheckCount = ref(0);

watch(
  () => expanded.value,
  (
    newVal: CustomsHandlingItemViewModel[],
    oldVal: CustomsHandlingItemViewModel[],
  ) => {
    if (newVal.length === 0) {
      currentHandlingItem.value = null;
    } else {
      const customsHandlingId = newVal[0].id;
      isLoading.value = true;
      CustomsController.getCustomsHandlingItemById(customsHandlingId)
        .then((response) => {
          currentOpenDialogCheckCount.value = 0;
          currentHandlingItem.value = response.data;
          isLoading.value = false;
          allowDakosyExportOptions.value = {} as DakosyExportWarningOptions;
          if (
            currentHandlingItem.value.value !== undefined &&
            currentHandlingItem.value !== null
          ) {
            fullView.value =
              currentHandlingItem.value.incoterm === INCOTERM_DAP;
            recalculateArticleFreightCosts();

            while (
              !shipmentOpenedDialogChecks[currentOpenDialogCheckCount.value](
                currentHandlingItem.value,
              )
            ) {
              currentOpenDialogCheckCount.value++;
            }
          } else {
            refresh();
          }
        })
        .catch((error) => {
          isLoading.value = false;
        });
    }
  },
);

watch(
  () => currentHandlingItem.value?.freightcostPercentage,
  (newVal: number, oldVal: number) => {
    updateArticlePrices.value = true;
    onZollwertUpdateNeeded();
  },
);

watch(
  () => currentHandlingItem.value?.autoClearanceFreightCosts,
  (newVal: number, oldVal: number) => {
    if (
      currentHandlingItem.value !== undefined &&
      currentHandlingItem.value !== null
    ) {
      recalculateArticleFreightCosts();
    }
  },
);

watch(
  () => currentHandlingItem.value?.autoClearanceFreightCostsCurrency,
  (newVal: string, oldVal: string) => {
    if (
      currentHandlingItem.value !== undefined &&
      currentHandlingItem.value !== null
    ) {
      recalculateArticleFreightCosts();
    }
  },
);

watch(
  () => currentAcDocument.value?.documentCode,
  (newVal: string, oldVal: string) => {
    if (skipAcDocumentCodeChangedWatch.value) {
      skipAcDocumentCodeChangedWatch.value = false;
      return;
    }

    let acDocumentType = acDocumentTypes.value.find((d) => d.code === newVal);
    if (acDocumentType !== undefined) {
      currentAcDocument.value!.description = acDocumentType.description ?? "";
    }
  },
);

const onZollwertUpdateNeeded = () => {
  if (
    currentHandlingItem.value !== null &&
    currentHandlingItem.value !== undefined
  ) {
    currentHandlingItem.value!.zollwert =
      currentHandlingItem.value!.articles.reduce(
        (prevValue, currentArticle) =>
          prevValue + (currentArticle.zollwert ?? 0),
        0,
      );
  }
};

onBeforeMount(() => {
  const toolbarButtons: ToolbarItem[] = [
    requestNewJobToolbarItem.value,
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Customs Import shipments", toolbarButtons);

  loadData();
});

const refresh = () => {
  loadData();
};

const loadData = () => {
  isLoading.value = true;
  CustomsController.getCustomsHandlingItems()
    .then((response) => {
      customsHandlingItems.value = response.data;
      isLoading.value = false;
    })
    .catch((error) => {
      isLoading.value = false;
    });

  dataApi
    .getDestinationCountries()
    .then((result) => (destinationCountries.value = result.data));

  dataApi
    .getCurrencies()
    .then((result) => (valuesCurrency.value = result.data));

  dataApi.getCodes().then((result) => (codes.value = result.data));

  dataApi.getIncoterms().then((result) => (incoterms.value = result.data));

  dataApi
    .getEuDestinationCountries()
    .then((result) => (euDestinationCountries.value = result.data));

  dataApi
    .getBusinessTypes()
    .then((result) => (typeOfBusinesses.value = result.data));

  dataApi
    .getOriginCountries()
    .then((result) => (originCountries.value = result.data));

  dataApi
    .getDepartureAirports()
    .then((result) => (departureAirports.value = result.data));

  dataApi
    .getDeliveryConditions()
    .then((result) => (deliveryConditions.value = result.data));

  dataApi
    .getDataTransportTypes()
    .then((result) => (transportTypes.value = result.data));

  dataApi.getColors().then((result) => (colors.value = result.data));

  dataApi
    .getExchangeRateCurrencies()
    .then((result) => (exchangeRateCurrencies.value = result.data));

  dataApi
    .getArticleTypes()
    .then((result) => (articleTypes.value = result.data));

  dataApi
    .getMeasurementUnits()
    .then((result) => (unitsOfMeasurement.value = result.data));

  dataApi
    .getAcDocumentTypes()
    .then((result) => (acDocumentTypes.value = result.data));
};

const recalculateArticleFreightCosts = () => {
  let freightCostPerArticle =
    currentHandlingItem.value!.autoClearanceFreightCosts !== null
      ? currentHandlingItem.value!.autoClearanceFreightCosts! /
        currentHandlingItem.value!.articles.length
      : 0;
  for (let article of currentHandlingItem.value!.articles) {
    article.frachtkosten = freightCostPerArticle;
    article.frachtkostenWahrung =
      currentHandlingItem.value!.autoClearanceFreightCostsCurrency;
  }
};

const exportToDakosy = () => {
  errorWithArticles.value = false;
  showDakosyExportWarning.value = false;
  allowDakosyExportContinueOnWarning.value = false;
  dakosyExportWarningMessage.value = "";

  recalculateArticleFreightCosts();
  observer
    .value!.validateWithInfo()
    .then((x) => {
      dakosyExportFailedErrors.value = [];

      if (
        currentHandlingItem.value!.articles === null ||
        currentHandlingItem.value!.articles === undefined ||
        currentHandlingItem.value!.articles.length === 0
      ) {
        errorWithArticles.value = true;
        dakosyExportFailedErrors.value.push(
          "At least one article needs be present to be able to export to Dakosy",
        );
        dakosyExportFailed.value = true;
      }

      let nonGroupedArticles = currentHandlingItem.value!.articles.filter(
        (a) => a.canBeGrouped,
      );
      if (nonGroupedArticles.length > 0) {
        showNotAllArticlesHaveTheirInfoSet.value = true;
        return;
      }

      if (!x.isValid) {
        const limitedViewValidationProviders = [
          "LimitedView-Bestimmungsbundesland",
          "LimitedView-Code",
          "LimitedView-Frachtkosten",
          "LimitedView-Frachtkosten-Currency",
        ];
        if (!fullView.value) {
          let allLimitedFieldsValid = true;
          for (let vp of limitedViewValidationProviders) {
            if (x.errors[vp].length !== 0) {
              allLimitedFieldsValid = false;
              break;
            }
          }

          if (allLimitedFieldsValid) {
            fullView.value = true;
          }
        }

        for (let errorObj in x.errors) {
          const value = x.errors[errorObj];
          if (value !== null && value !== undefined && value.length > 0) {
            dakosyExportFailedErrors.value.push(value[0]);
          }
        }

        dakosyExportFailed.value = true;
        allowDakosyExportOptions.value = {} as DakosyExportWarningOptions;
      } else if (!dakosyExportFailed.value) {
        if (
          currentHandlingItem.value!.receiverType === "B" &&
          !currentHandlingItem.value!.preTaxDeduction &&
          !allowDakosyExportOptions.value.allowReceiverTypeBAndNopreTaxDeduction
        ) {
          showDakosyExportWarning.value = true;
          allowDakosyExportContinueOnWarning.value = true;
          dakosyExportWarningMessage.value =
            "The receiver type is 'B' and Vorsteuerabzug is not checked. Are you sure you would like to continue?";
          allowDakosyExportOptions.value.allowReceiverTypeBAndNopreTaxDeduction =
            true;
          return;
        }

        if (
          currentHandlingItem.value!.receiverType === "P" &&
          currentHandlingItem.value!.preTaxDeduction &&
          !allowDakosyExportOptions.value.allowReceiverTypePAndPreTaxDeduction
        ) {
          showDakosyExportWarning.value = true;
          allowDakosyExportContinueOnWarning.value = true;
          dakosyExportWarningMessage.value =
            "The receiver type is 'P' and Vorsteuerabzug is checked. Are you sure you would like to continue?";
          allowDakosyExportOptions.value.allowReceiverTypePAndPreTaxDeduction =
            true;
          return;
        }

        const euCode = codes.value.find(
          (c) => c.id === currentHandlingItem.value?.customsCodeId,
        )?.code;
        if (
          currentHandlingItem.value!.zollwert !== undefined &&
          currentHandlingItem.value!.zollwert <= 150 &&
          euCode !== "C07" &&
          !allowDakosyExportOptions.value.allowZollwertLessThan150AndNotC07Code
        ) {
          showDakosyExportWarning.value = true;
          allowDakosyExportContinueOnWarning.value = true;
          dakosyExportWarningMessage.value =
            "The calculated Zollwert is €150 or less and the selected code is not 'Wert unter 150€'. Are you sure you would like to continue?";
          allowDakosyExportOptions.value.allowZollwertLessThan150AndNotC07Code =
            true;
          return;
        }

        if (
          currentHandlingItem.value!.zollwert !== undefined &&
          currentHandlingItem.value!.zollwert >= 150 &&
          euCode !== "" &&
          !allowDakosyExportOptions.value.allowZollwertMoreThan150AndNotOhneCode
        ) {
          showDakosyExportWarning.value = true;
          allowDakosyExportContinueOnWarning.value = true;
          dakosyExportWarningMessage.value =
            "The calculated Zollwert is €150 or more and the selected code is not 'ohne code'. Are you sure you would like to continue?";
          allowDakosyExportOptions.value.allowZollwertMoreThan150AndNotOhneCode =
            true;
          return;
        }

        errorWithArticles.value = false;
        showDakosyExportWarning.value = false;
        allowDakosyExportContinueOnWarning.value = false;
        dakosyExportWarningMessage.value = "";

        save(() => {
          CustomsController.exportToDakosy(currentHandlingItem.value!.id).then(
            (result) => {
              currentHandlingItem.value!.dakosyExport = result.data;
              refresh();
            },
          );
        });
      }
    })
    .catch((error) => {});
};

const onRequestJobsConfirm = () => {
  isLoading.value = true;
  CustomsController.getCustomsHandlingItems()
    .then((response) => {
      customsHandlingItems.value = response.data;
    })
    .catch((error) => {
      emitError("Something went wrong while retrieving the jobs");
    })
    .finally(() => (isLoading.value = false));
};

const formatDateTime = (dateTime: Date) => {
  if (dateTime === null || dateTime === undefined) {
    return "";
  }

  return moment(dateTime).format("DD.MM.YYYY");
};

const formatDateTimeWithTime = (dateTime: Date) => {
  if (dateTime === null || dateTime === undefined) {
    return "";
  }

  return moment(dateTime).format("DD.MM.YYYY HH:mm");
};

const getRoundedTo2DigitsValue = (value: string) => {
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    value === "NaN" ||
    Number.isNaN(value)
  ) {
    return "0.00";
  }
  return parseFloat(value).toFixed(2);
};

const getCodeText = (item: CodeViewModel) => {
  return `${item.code} - ${item.name}`;
};

const getTypeOfBusinessText = (item: TypeOfBusinessViewModel) => {
  return `${item.code} - ${item.description}`;
};

const getDestinationCountryText = (item: DestinationCountryViewModel) => {
  return `${item.name} - ${item.description}`;
};

const getCountryOfOriginText = (item: CountryOfOriginViewModel) => {
  return `${item.code} - ${item.description}`;
};

const getDepartureAirportText = (item: DepartureAirportViewModel) => {
  return `${item.code} - ${item.description}`;
};

const getTransportTypeText = (item: TransportTypeViewModel) => {
  return `${item.code} - ${item.description}`;
};

const save = (saveSuccessfulCallback: Function) => {
  if (
    currentHandlingItem.value !== undefined &&
    currentHandlingItem.value !== null
  ) {
    recalculateArticleFreightCosts();
  }

  CustomsController.saveCustomsHandlingItem(currentHandlingItem.value!)
    .then((result) => {
      currentHandlingItem.value = result.data;
      const item = customsHandlingItems.value!.find(
        (x) => x.id === currentHandlingItem.value!.id,
      );
      item!.mawb = currentHandlingItem.value.mawb;
      item!.hawb = currentHandlingItem.value.hawb;
      item!.shipperName = currentHandlingItem.value.shipperName;
      item!.consigneeContact = currentHandlingItem.value.consigneeContact;
      item!.incoterm = currentHandlingItem.value.incoterm;
      item!.pieces = currentHandlingItem.value.pieces;
      item!.value = currentHandlingItem.value.value;
      item!.grossWeight = currentHandlingItem.value.grossWeight;
      item!.customsDeadline = currentHandlingItem.value.customsDeadline;

      saveSuccessful.value = true;
      if (saveSuccessfulCallback) {
        saveSuccessfulCallback();
      }
    })
    .catch((error) => {});
};

const groupArticles = (articleGroupVm: GroupArticlesViewModel) => {
  CustomsController.groupArticles(articleGroupVm)
    .then((result) => {
      const nonGroupedArticles = currentHandlingItem.value!.articles.filter(
        (a) => !a.groupArticle,
      );
      currentHandlingItem.value!.articles = result.data;
      for (let article of nonGroupedArticles) {
        let newArticle = currentHandlingItem.value!.articles.find(
          (a) => a.id === article.id,
        );
        if (newArticle !== undefined) {
          newArticle.groupArticle = false;
        }
      }

      recalculateArticleFreightCosts();
    })
    .catch((error) => {});
};

const fullViewChanged = (value: boolean) => {
  fullView.value = value;
};

const openEscalationModal = () => {
  escalationRemarks.value = "";
  escalationCategory.value = null;
  sendingEscalation.value = false;
  showEscalationModal.value = true;
};

const escalateToNextLevel = () => {
  if (sendingEscalation.value) {
    return;
  }

  escalationModalObserver.value!.validateWithInfo().then((x) => {
    if (!x.isValid) {
      return;
    }

    sendingEscalation.value = true;
    let vm = {
      customsHandlingItemId: currentHandlingItem.value!.id,
      escalationRemarks: escalationRemarks.value,
      escalationCategory: escalationCategory.value,
    } as EscalateToNextLevelViewModel;
    CustomsController.escalateToNextLevel(vm)
      .then((result) => {
        refresh();
        sendingEscalation.value = false;
        showEscalationModal.value = false;
      })
      .catch((error) => {
        emitError(error.message);
        sendingEscalation.value = false;
      });
  });
};

const addDocument = () => {
  let acDocument = {
    customsHandlingItemId: currentHandlingItem.value!.id,
    description: acDocumentTypes.value[0].description,
    documentCode: acDocumentTypes.value[0].code,
    date: new Date(),
  } as CustomsAcDocumentViewModel;
  skipAcDocumentCodeChangedWatch.value = true;
  currentAcDocument.value = acDocument;
  documentDateModel.value = moment(currentAcDocument.value.date).format(
    "YYYY-MM-DD",
  );
  showAddDocumentDialog.value = true;
};

const editDocument = (document: CustomsAcDocumentViewModel) => {
  skipAcDocumentCodeChangedWatch.value = true;
  currentAcDocument.value = JSON.parse(JSON.stringify(document));
  documentDateModel.value = moment(currentAcDocument.value!.date).format(
    "YYYY-MM-DD",
  );
  showAddDocumentDialog.value = true;
};

const saveDocument = () => {
  if (
    currentHandlingItem.value!.acDocuments === undefined ||
    currentHandlingItem.value!.acDocuments === null
  ) {
    currentHandlingItem.value!.acDocuments = [];
  }

  if (currentAcDocument.value?.id !== undefined) {
    const existingAcDocument = currentHandlingItem.value!.acDocuments.find(
      (d) => d.id === currentAcDocument.value!.id,
    );
    if (existingAcDocument !== undefined) {
      existingAcDocument.date = currentAcDocument.value.date;
      existingAcDocument.description = currentAcDocument.value.description;
      existingAcDocument.documentCode = currentAcDocument.value.documentCode;
      existingAcDocument.number = currentAcDocument.value.number;

      showAddDocumentDialog.value = false;
      return;
    }
  }

  currentAcDocument.value!.id = 0;
  currentHandlingItem.value!.acDocuments.push(currentAcDocument.value!);
  showAddDocumentDialog.value = false;
};

const cancelSavingDocument = () => {
  showAddDocumentDialog.value = false;
};

const deleteDocument = (acDocument: CustomsAcDocumentViewModel) => {
  skipAcDocumentCodeChangedWatch.value = true;
  currentAcDocument.value = acDocument;
  showConfirmDeleteAcDocumentDialog.value = true;
};

const deleteDocumentConfirm = () => {
  let index = currentHandlingItem.value!.acDocuments.indexOf(
    currentAcDocument.value!,
  );
  if (index !== -1) {
    currentHandlingItem.value!.acDocuments.splice(index, 1);
  }

  showConfirmDeleteAcDocumentDialog.value = false;
};

const currentAcDocumentDate = computed(() => {
  if (currentAcDocument.value!.date === undefined) {
    return "";
  }

  return moment(currentAcDocument.value!.date).format("YYYY-MM-DD");
});

const setDocumentDate = () => {
  showDocumentDatePicker.value = false;
  currentAcDocument.value!.date = moment(
    documentDateModel.value,
    "YYYY-MM-DD",
  ).toDate();
};

watch(
  () => documentDateModel.value,
  (newVal: string, oldVal: string) => {
    currentAcDocument.value!.date = moment(newVal, "YYYY-MM-DD").toDate();
  },
);

const rechnungsPreis = computed(() => {
  if (currentHandlingItem === undefined || currentHandlingItem === null) {
    return "";
  }

  const reduce = currentHandlingItem.value!.articles.reduce(
    (prevValue, currValue) =>
      prevValue + (parseFloat((currValue.artikelPreis ?? 0).toString()) ?? 0),
    0,
  );

  return reduce.toFixed(2);
});

const takeValueFromCustomer = () => {
  showValuesAreDifferentDialog.value = false;
  if (!currentHandlingItem.value) {
    return;
  }

  currentHandlingItem.value.value = currentHandlingItem.value.valueOfGoods ?? 0;
  currentHandlingItem.value.valueCurrency =
    currentHandlingItem.value.valueOfGoodsCurrency;
};

const takeValueFromShipment = () => {
  showValuesAreDifferentDialog.value = false;
  if (!currentHandlingItem.value) {
    return;
  }

  currentHandlingItem.value.valueOfGoods = currentHandlingItem.value.value;
  currentHandlingItem.value.valueOfGoodsCurrency =
    currentHandlingItem.value.valueCurrency;
};

const takeFreightcostsFromCustomer = () => {
  showFreightcostsAreDifferentDialog.value = false;
  if (!currentHandlingItem.value) {
    return;
  }

  currentHandlingItem.value.autoClearanceFreightCosts =
    currentHandlingItem.value.freightCosts;
  currentHandlingItem.value.autoClearanceFreightCostsCurrency =
    currentHandlingItem.value.freightCostsCurrency;
  recalculateArticleFreightCosts();
};

const takeFreightcostsFromShipment = () => {
  showFreightcostsAreDifferentDialog.value = false;
  if (!currentHandlingItem.value) {
    return;
  }

  currentHandlingItem.value.freightCosts =
    currentHandlingItem.value.autoClearanceFreightCosts;
  currentHandlingItem.value.freightCostsCurrency =
    currentHandlingItem.value.autoClearanceFreightCostsCurrency;
  recalculateArticleFreightCosts();
};

const closeDialogCheck = (callback: Function) => {
  callback();

  if (
    currentOpenDialogCheckCount.value <
    shipmentOpenedDialogChecks.length - 1
  ) {
    currentOpenDialogCheckCount.value++;
    shipmentOpenedDialogChecks[currentOpenDialogCheckCount.value](
      currentHandlingItem.value,
    );
  }
};
</script>
