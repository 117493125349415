import { render, staticRenderFns } from "./CustomsExportShipmentDetailViewNew.vue.html?vue&type=template&id=758d5a0e"
import script from "./CustomsExportShipmentDetailViewNew.ts?vue&type=script&lang=ts&external"
export * from "./CustomsExportShipmentDetailViewNew.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports