import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DatePicker extends Vue {
    @Prop({ required: true })
    value!: string | string[] | null;

    @Prop({ default: 'Date' })
    label!: string;

    @Prop({ default: () => [] })
    errorMessages!: string[];

    @Prop({ default: false })
    range!: boolean;

    @Prop({
        default: 'date',
        validator: (value: string) => ['date', 'month'].includes(value),
    })
    type!: string;

    get dateDisplayText() {
        if (!this.value) {
            return '';
        }

        return Array.isArray(this.value)
            ? this.value?.map((c) => c.split('T')[0]).join(' - ')
            : this.value.split('T')[0];
    }

    get dateValue() {
        if (!this.value) {
            return this.value;
        }
        return Array.isArray(this.value)
            ? this.value?.map((c) => c.split('T')[0])
            : this.value?.split('T')[0];
    }

    set dateValue(value) {
        this.$emit('input', value);
    }
}
