import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Articles from '@/components/Articles.vue.html';
import moment from 'moment';
import { ArticleTypeViewModel, CodeViewModel, CurrencyViewModel, DestinationCountryViewModel, TypeOfBusinessViewModel, UnitOfMeasurementViewModel } from '../openapi';
import { CustomsHandlingItemViewModel } from '../api/viewmodels/CustomsHandlingItemViewModel';

@Component({
    components: {
        Articles
    }
})
export default class LimitedCustomsView extends Vue {
    @Prop({})
    valuesCurrency!: CurrencyViewModel[];

    @Prop({})
    handlingItem!: CustomsHandlingItemViewModel;

    @Prop({})
    destinationCountries!: DestinationCountryViewModel[];

    @Prop({})
    codes!: CodeViewModel[];

    @Prop({})
    getCodeText!: Function

    @Prop({})
    getDestinationCountryText!: Function

    @Prop({ default: false })
    errorWithArticles!: boolean;

    @Prop({})
    exchangeRateCurrencies!: CurrencyViewModel[];

    @Prop({})
    getRoundedTo2DigitsValue!: Function;

    @Prop({})
    updateArticlePrices!: boolean;

    @Prop({})
    articleTypes!: ArticleTypeViewModel[];

    @Prop({})
    unitsOfMeasurement!: UnitOfMeasurementViewModel[];

    @Prop({})
    openEscalationModal!: Function;

    @Prop({})
    typeOfBusinesses!: TypeOfBusinessViewModel[];

    @Prop({})
    getTypeOfBusinessText!: Function;

    formatDateTimeWithTime(dateTime: Date) {
        if (dateTime === null || dateTime === undefined) {
            return "";
        }

        return moment(dateTime).format('DD.MM.YYYY HH:mm');
    }
}