<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      item-key="id"
      fixed-footer
      fixed-header
      dense
      height="calc(100vh - 280px)"
      class="elevation-1"
      :loading="loading"
    >
      <template #top>
        <div class="filters">
          <v-text-field
            v-model="filters.search"
            placeholder="Search"
            @keyup.enter="onFilterChanged"
            append-icon="mdi-magnify"
            :disabled="loading"
          ></v-text-field>

          <v-autocomplete
            v-model="filters.customerId"
            :items="dataStore.generalCustomers"
            item-value="id"
            item-text="shortName"
            label="General customer"
            clearable
            @change="onFilterChanged"
          ></v-autocomplete>

          <v-select
            v-model="filters.incotermId"
            :items="dataStore.incoterms"
            item-value="id"
            item-text="name"
            label="Incoterm"
            @change="onFilterChanged"
            clearable
          ></v-select>

          <DatePicker
            v-model="filters.eta"
            label="ETA"
            @input="onFilterChanged"
          ></DatePicker>

          <v-select
            v-model="filters.level"
            :items="dataStore.jobLevels"
            item-value="level"
            item-text="level"
            label="Job level"
            @change="onFilterChanged"
            clearable
          ></v-select>

          <IndeterminateCheckbox
            v-model="filters.isHandled"
            :label="'Is handled?'"
            @change="onFilterChanged"
          ></IndeterminateCheckbox>

          <IndeterminateCheckbox
            v-model="filters.isBusiness"
            :label="'Is business?'"
            @change="onFilterChanged"
          ></IndeterminateCheckbox>
        </div>
      </template>
      <template #item.eta="{ item }">{{
        item.eta | formatDate("DD-MM-YYYY HH:mm:ss")
      }}</template>
      <template #item.isJobHandled="{ item }"
        ><v-icon :color="item.isJobHandled ? 'success' : 'error'"
          >{{ item.isJobHandled ? "mdi-check" : "mdi-close" }}
        </v-icon></template
      >
      <template #item.isBusinessJob="{ item }"
        ><v-icon :color="item.isBusinessJob ? 'success' : 'error'"
          >{{ item.isBusinessJob ? "mdi-check" : "mdi-close" }}
        </v-icon></template
      >
      <template #item.isPriorityJob="{ item }"
        ><v-icon :color="item.isPriorityJob ? 'success' : 'error'"
          >{{ item.isPriorityJob ? "mdi-check" : "mdi-close" }}
        </v-icon></template
      >
      <template #item.actions="{ item }"
        ><v-btn color="primary" class="ma-2" @click="editItem(item)"
          ><v-icon left>mdi-pencil</v-icon>Edit</v-btn
        ></template
      >
    </v-data-table>

    <ValidationObserver slim v-slot="{ invalid }">
      <ConfirmDialog
        v-if="showDialog && editableItem"
        v-model="showDialog"
        title="Edit backlog item"
        :is-loading="dialogLoading"
        :is-confirm-disabled="invalid"
        @cancel="editableItem = null"
        @confirm="onEditConfirmed"
      >
        <ValidationProvider name="ETA" rules="required" v-slot="{ errors }">
          <DateTimePicker
            v-model="editableItem.eta"
            label="ETA"
            :error-messages="errors"
          ></DateTimePicker>
        </ValidationProvider>
        <v-checkbox
          v-model="editableItem.isPriorityJob"
          label="Priority shipment"
        ></v-checkbox>
      </ConfirmDialog>
    </ValidationObserver>
  </Fragment>
</template>
<script setup lang="ts">
import { emitError, emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  DistributedImportJobBacklogItem,
  DistributedImportJobsApi,
} from "@/openapi";
import { FooterOptions } from "@/types/types";
import { onBeforeMount, ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import DatePicker from "@/components/editors/DatePicker.vue.html";
import IndeterminateCheckbox from "@/components/editors/IndeterminateCheckbox.vue";
import { useSorting } from "@/composables/sort";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue.html";
import { useDataStore } from "@/stores/data-store";

interface DistributedImportJobsBacklogFilters {
  search: string;
  eta: string | null;
  incotermId: number | null;
  customerId: number | null;
  isHandled: boolean | null;
  isBusiness: boolean | null;
  level: number | null;
}

interface DistributedImportJobBacklogEditableItem {
  id: number;
  isPriorityJob: boolean;
  eta: string;
}

const distributedImportJobsApi = new DistributedImportJobsApi(undefined, "");
const dataStore = useDataStore();
const emits = defineEmits(["PageInfoReceived"]);

const headers = ref<DataTableHeader[]>([
  {
    text: "ETA date/time",
    value: "eta",
    align: "start",
    width: 300,
    sortable: true,
  },
  { text: "Incoterm", value: "incoterm", sortable: false },
  {
    text: "HAWB",
    value: "hawbNumber",
    sortable: false,
  },
  {
    text: "General customer",
    value: "generalCustomer",
    sortable: false,
  },
  {
    text: "MAWB",
    value: "mawbNumber",
    sortable: false,
  },
  {
    text: "MRN",
    value: "mrn",
    sortable: false,
  },
  {
    text: "Job level",
    value: "jobLevel",
    sortable: false,
  },
  {
    text: "Job handled?",
    value: "isJobHandled",
    sortable: false,
  },
  {
    text: "Is business?",
    value: "isBusinessJob",
    sortable: false,
  },
  {
    text: "Priority shipment",
    value: "isPriorityJob",
    sortable: false,
  },
  {
    text: " ",
    value: "actions",
    sortable: false,
    width: "6em",
  },
]);
const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const filters = ref<DistributedImportJobsBacklogFilters>({
  search: "",
  eta: null,
  incotermId: null,
  isHandled: false,
  level: null,
  customerId: null,
  isBusiness: null,
});

const { sortBy, sortDesc } = useSorting(options);

const items = ref<DistributedImportJobBacklogItem[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);

const showDialog = ref(false);
const dialogLoading = ref(false);
const editableItem = ref<DistributedImportJobBacklogEditableItem | null>(null);

const onFilterChanged = async () => {
  if (options.value.page === 1) {
    await getBacklog();
  } else {
    options.value.page = 1;
  }
};

const refresh = async () => {
  await getBacklog();
};

const getBacklog = async (page?: number) => {
  page ??= options.value.page;

  loading.value = true;
  try {
    const response =
      await distributedImportJobsApi.getDistributedImportJobsBacklog(
        filters.value.search,
        filters.value.eta ?? undefined,
        filters.value.incotermId ?? undefined,
        filters.value.customerId ?? undefined,
        filters.value?.isHandled ?? undefined,
        filters.value.isBusiness ?? undefined,
        filters.value.level ?? undefined,
        sortBy.value,
        sortDesc.value,
        page,
        options.value.itemsPerPage,
      );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError("Something went while retrieving the jobs");
  }
  loading.value = false;
};

const editItem = (item: DistributedImportJobBacklogItem) => {
  editableItem.value = {
    id: item.id!,
    eta: item.eta!,
    isPriorityJob: item.isPriorityJob ?? false,
  };
  showDialog.value = true;
};

const onEditConfirmed = async () => {
  dialogLoading.value = true;
  try {
    await distributedImportJobsApi.editDistributedImportJobsBacklogItem({
      id: editableItem.value?.id,
      eta: editableItem.value?.eta,
      isPriorityJob: editableItem.value?.isPriorityJob,
    });
    emitSuccess("Successfully edited the backlog item");
    showDialog.value = false;
    editableItem.value = null;
    await getBacklog();
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while saving the changes");
  }
  dialogLoading.value = false;
};

watch(
  () => options.value,
  async (newValue, oldValue) => {
    await getBacklog();
  },
  { deep: true },
);

onBeforeMount(async () => {
  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => refresh(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Distributed import jobs", toolbarButtons);

  await Promise.all([
    dataStore.fetchGeneralCustomers(),
    dataStore.fetchIncoterms(),
    dataStore.fetchJobLevels(),
  ]);
});
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 15px;
}
</style>
