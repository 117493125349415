import { render, staticRenderFns } from "./LimitedCustomsView.vue.html?vue&type=template&id=5f870a2c"
import script from "./LimitedCustomsView.ts?vue&type=script&lang=ts&external"
export * from "./LimitedCustomsView.ts?vue&type=script&lang=ts&external"
import style0 from "./LimitedCustomsView.vue.html?vue&type=style&index=0&id=5f870a2c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports