

import axios, { AxiosPromise, AxiosTransformer } from 'axios';

let ISO_8601 = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})$/;
let transformDate = function (data: any) {
    if(data === undefined || data === null)
    {
        return null;
    }

    Object.keys(data).forEach(function (k) {
        if(data[k] instanceof Object) {
            transformDate(data[k]);
        } else {
            if (ISO_8601.test(data[k])) {
                data[k] = new Date(Date.parse(data[k]));
            }
        }
    });
    return data;
};
let transformResponse = (axios.defaults.transformResponse as AxiosTransformer[]).concat(transformDate)

import { EmployeeViewModel } from './viewmodels/EmployeeViewModel'
import { PagedResultEmployees } from './viewmodels/PagedResultEmployees'


        
    // GetPagedEmployees
    export function getPagedEmployees (amount: number, page: number, orderByField: string, orderBy: string) : AxiosPromise<PagedResultEmployees> {
        return axios({
            url: `api/Employees/getPagedEmployees/?amount=${amount}&page=${page}&orderByField=${encodeURIComponent(orderByField)}&orderBy=${encodeURIComponent(orderBy)}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // SaveEmployee
    export function saveEmployee (model: EmployeeViewModel) : AxiosPromise<EmployeeViewModel> {
        return axios({
            url: `api/Employees/saveEmployee/`, 
            method: "post",
            data: model,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };

