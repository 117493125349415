<template>
  <Fragment>
    <ValidationObserver v-slot="{ dirty }" ref="observer" slim>
      <tr v-if="item">
        <ValidationProvider
          :ref="'description+' + item.id"
          v-slot="{ errors }"
          name="Description"
          :vid="'description+' + item.id"
          rules="required|max:300"
          slim
        >
          <EditableValue
            v-model="item.description"
            wrapper="td"
            :check-difference="false"
            :error-messages="errors"
            counter
          >
          </EditableValue>
        </ValidationProvider>
        <ValidationProvider
          :ref="'wtn+' + item.id"
          v-slot="{ errors }"
          name="WTN"
          :vid="'wtn+' + item.id"
          rules="required|max:11"
          slim
        >
          <EditableValue
            v-model="item.wtn"
            wrapper="td"
            :check-difference="false"
            :error-messages="errors"
            counter
          >
          </EditableValue>
        </ValidationProvider>
        <td>
          <v-btn small color="primary" @click="showVariants()"
            >Variants ({{ item.variantsCount }})</v-btn
          >
        </td>
        <ValidationProvider
          :ref="'goodsDescription+' + item.id"
          v-slot="{ errors }"
          name="Warenbeschreibung"
          :vid="'goodsDescription+' + item.id"
          rules="max:240"
          slim
        >
          <EditableValue
            v-model="item.goodsDescription"
            wrapper="td"
            :check-difference="false"
            :error-messages="errors"
            counter
          >
          </EditableValue>
        </ValidationProvider>
        <ValidationProvider
          :ref="'category+' + item.id"
          name="Kategorie"
          :vid="'category+' + item.id"
          slim
        >
          <EditableDropdown
            v-model="item.categoryIds"
            :items="mainCategories"
            :return-object="false"
            :multiple="true"
            item-value="id"
            item-text="name"
            :check-difference="false"
            wrapper="td"
          >
          </EditableDropdown>
        </ValidationProvider>
        <ValidationProvider
          :ref="'subCategory+' + item.id"
          name="Sub-Kategorie"
          :vid="'subCategory+' + item.id"
          slim
        >
          <EditableDropdown
            v-model="item.subcategoryIds"
            :items="subcategories"
            :return-object="false"
            :multiple="true"
            item-value="id"
            item-text="name"
            :check-difference="false"
            wrapper="td"
          >
          </EditableDropdown>
        </ValidationProvider>
        <ValidationProvider
          :ref="'unitOfMeasurement+' + item.id"
          name="Unit of measurement"
          :vid="'unitOfMeasurement+' + item.id"
          slim
        >
          <EditableDropdown
            v-model="item.unitOfMeasurement"
            :items="enrichedMeasurementUnits"
            :return-object="false"
            item-value="id"
            item-text="itemText"
            display-text="displayText"
            :check-difference="false"
            wrapper="td"
          >
          </EditableDropdown>
        </ValidationProvider>
        <ValidationProvider
          :ref="'zollmengeUnitOfMeasurement+' + item.id"
          name="Zollmenge unit of measurement"
          :vid="'zollmengeUnitOfMeasurement+' + item.id"
          slim
        >
          <EditableDropdown
            v-model="item.zollMengeUnitOfMeasurement"
            :items="enrichedMeasurementUnits"
            :return-object="false"
            item-value="id"
            item-text="itemText"
            display-text="displayText"
            :check-difference="false"
            wrapper="td"
          >
          </EditableDropdown>
        </ValidationProvider>
        <td>
          <v-btn
            small
            :color="amountOfKeywords ? 'primary' : ''"
            @click="showKeywordsDialog = true"
            >Keywords ({{ amountOfKeywords }})</v-btn
          >
        </td>
        <ValidationProvider
          :ref="'dontUseForImpost+' + item.id"
          v-slot="{ failed }"
          name="Don't use for Impost'"
          :vid="'dontUseForImpost+' + item.id"
          slim
        >
          <td :class="{ red: failed }">
            <v-checkbox
              v-model="item.dontUseForImpost"
              class="mt-0 pt-0"
              hide-details
            ></v-checkbox>
          </td>
        </ValidationProvider>
        <td>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                :disabled="!dirty && !keywordsUpdated"
                small
                class="mr-1"
                v-bind="attrs"
                @click="saveItem(item)"
                v-on="on"
              >
                mdi-content-save
              </v-icon>
            </template>
            <span v-if="item.parent && item.parent.needsSaving"
              >The parent article needs to be saved first</span
            >
            <span v-else>Save</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                small
                class="mr-1"
                v-bind="attrs"
                @click="$emit('deleteArticle', item)"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete article</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                small
                class="mr-1"
                v-bind="attrs"
                @click="duplicateItem(item)"
                v-on="on"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>Duplicate article</span>
          </v-tooltip>
        </td>
      </tr>
    </ValidationObserver>

    <KeywordsDialog
      v-if="showKeywordsDialog"
      v-model="showKeywordsDialog"
      :keywords="item.keywords"
      @confirm="onKeywordsConfirm"
    ></KeywordsDialog>

    <VariantDialog
      :model-value="articleVariants"
      :model-open="showVariantDialog"
      :loading="loadingVariants"
      @cancel="showVariantDialog = false"
      @save="saveVariants"
    ></VariantDialog>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError, emitErrorWithFallback } from "@/event-bus";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { computed, ref, watch } from "vue";
import {
  AcDocumentTypeViewModel,
  ArticleCategoryDto,
  CountryOfOriginViewModel,
  CustomsArticleKeywordDto,
  CustomsArticleViewModel,
  UnitOfMeasurementViewModel,
  CustomsArticlesApi,
  CustomsArticleVariantsViewModel,
} from "../openapi";
import EditableDropdown from "@/components/EditableDropdown.vue";
import EditableValue from "@/components/editors/EditableValue.vue";
import KeywordsDialog from "@/components/dialogs/KeywordsDialog.vue";
import VariantDialog from "@/components/dialogs/CustomsArticleVariantsDialog.vue";

interface ArticlePageArticleRowProps {
  item: CustomsArticleViewModel;
  measurementUnits: UnitOfMeasurementViewModel[];
  acDocumentTypes: AcDocumentTypeViewModel[];
  countries: CountryOfOriginViewModel[];
  categories: ArticleCategoryDto[];
}

const props = defineProps<ArticlePageArticleRowProps>();
const emits = defineEmits(["saveArticle", "copyArticleTree", "refresh"]);

const articleVariants = ref<CustomsArticleVariantsViewModel>();
const observer = ref<InstanceType<typeof ValidationObserver>>();
const showKeywordsDialog = ref(false);
const showVariantDialog = ref(false);
const loadingVariants = ref(false);

const keywordsUpdated = ref(false);
const customsArticlesApi = new CustomsArticlesApi(undefined, "");

watch(
  () => props.item.needsSaving,
  (newVal: boolean | undefined, oldVal: boolean | undefined) => {
    if (newVal) {
      saveItem(props.item);
    }
  },
);

watch(
  () => props.item.categoryIds,
  (newVal, oldVal) => {
    const selectedSubcategories = props.categories.filter(
      (c) =>
        props.item.subcategoryIds?.find((sci) => sci === c.id) !== undefined,
    );
    props.item.subcategoryIds = selectedSubcategories
      .filter((sc) => newVal?.find((c) => (c === sc.parentId) !== undefined))
      .map((sc) => sc.id);
  },
);

const saveItem = async (item: CustomsArticleViewModel) => {
  const isValid = await validateItem(item);
  if (!isValid) {
    return;
  }

  emits("saveArticle", item);
  keywordsUpdated.value = false;
};

const validateItem = async (
  item: CustomsArticleViewModel,
): Promise<boolean> => {
  return await observer.value!.validate();
};

const duplicateItem = (item: CustomsArticleViewModel) => {
  const newItem = JSON.parse(
    JSON.stringify(item, (key, value) => {
      if (key === "parent") {
        return undefined;
      }

      return value;
    }),
  ) as CustomsArticleViewModel;

  emits("copyArticleTree", newItem);
  keywordsUpdated.value = false;
};

const getItemText = (
  item:
    | CountryOfOriginViewModel
    | AcDocumentTypeViewModel
    | UnitOfMeasurementViewModel,
) => {
  return `${item.code} | ${item.description}`;
};

const enrichedMeasurementUnits = computed(() => {
  return props.measurementUnits.map((item) => ({
    ...item,
    itemText: getItemText(item),
    displayText: getMeasurementUnitDisplayText(item),
  }));
});

const mainCategories = computed(() => {
  return props.categories.filter((c) => !c.parentId);
});

const subcategories = computed(() => {
  const selectedCategoryIds = props.item.categoryIds;
  if (!selectedCategoryIds || !selectedCategoryIds.length) {
    return [];
  }
  return props.categories.filter(
    (c) => selectedCategoryIds.find((id) => id === c.parentId) !== undefined,
  );
});

const amountOfKeywords = computed(() => {
  return props.item?.keywords?.length ?? 0;
});

const getMeasurementUnitDisplayText = (
  item: UnitOfMeasurementViewModel,
): string => {
  return getDisplayText(props.measurementUnits, item);
};

const getDisplayText = (
  items: (AcDocumentTypeViewModel | UnitOfMeasurementViewModel)[],
  item: AcDocumentTypeViewModel | UnitOfMeasurementViewModel,
) => {
  const foundItem = items.find((c) => c.code === item.code);
  return !foundItem ? "x" : foundItem.code!;
};

const onKeywordsConfirm = (data: CustomsArticleKeywordDto[]) => {
  props.item.keywords = data;
  showKeywordsDialog.value = false;
  keywordsUpdated.value = true;
};

const showVariants = () => {
  showVariantDialog.value = true;
  loadingVariants.value = true;

  customsArticlesApi
    .getCustomsArticleVariants(props.item.id)
    .then((response) => {
      articleVariants.value = response.data;
      loadingVariants.value = false;
    });
};

const saveVariants = async (model: CustomsArticleVariantsViewModel) => {
  loadingVariants.value = true;
  try {
    await customsArticlesApi.saveCustomsArticleVariants(model);
    showVariantDialog.value = false;
    articleVariants.value = undefined;
    props.item.variantsCount = model.variants?.length ?? 0;
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "An error occurred while saving the article variants",
    );
  } finally {
    loadingVariants.value = false;
  }
};
</script>
