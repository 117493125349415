<template>
    <Fragment>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalAmountOfItems"
            :options.sync="options"
            :footer-props="footerOptions"
            item-key="id"
            fixed-footer
            fixed-header
            class="elevation-1"
            height="calc(100vh - 171px)"
            :loading="loading"
        >
            <template #item.fileName="{ item }">
                <div v-for="dutiesTaxFile in item.items">
                    {{ dutiesTaxFile.fileName }}
                </div>
            </template>
            <template #item.fileExtension="{ item }">
                <div v-for="dutiesTaxFile in item.items">
                    {{ dutiesTaxFile.fileExtension }}
                </div>
            </template>
            <template #item.createDate="{ item }">
                {{ item.createDate | formatDate }}
            </template>
            <template #item.actions="{ item }">
                <div class="duties-taxes-checkup__actions">
                    <v-btn
                        v-if="item.canUpload"
                        color="info"
                        :disabled="item.isLoading"
                        small
                        @click="
                            (selectedItem = item), (showUploadDialog = true)
                        "
                    >
                        <v-icon left>mdi-upload</v-icon>
                        Upload pdf
                    </v-btn>
                    <v-btn
                        v-if="item.downloadId"
                        color="warning"
                        @click="onDownloadFile(item.downloadId)"
                        :disabled="item.isLoading"
                        small
                    >
                        <v-icon left>mdi-download</v-icon>
                        Download
                    </v-btn>
                    <v-btn
                        color="success"
                        :disabled="item.isLoading"
                        @click="(showDialog = true), (selectedItem = item)"
                        small
                    >
                        <v-icon left>mdi-connection</v-icon>
                        Connect to existing
                    </v-btn>
                    <v-btn
                        color="success"
                        :disabled="item.isLoading"
                        :loading="isRetrievingPrefillData"
                        @click="onConnectToNew(item)"
                        small
                    >
                        <v-icon left>mdi-new-box</v-icon>
                        Connect to new
                    </v-btn>
                    <v-btn
                        color="error"
                        @click="deleteItem(item)"
                        :disabled="item.isLoading && !item.isDeleting"
                        :loading="item.isDeleting"
                        small
                    >
                        <v-icon left>mdi-delete</v-icon>
                        Delete
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <FindShipmentByHawbDialog
            v-if="showDialog && selectedItem"
            v-model="showDialog"
            :is-loading="isConnectingToExistingShipment"
            @confirm="onSelectedExistingShipment"
        >
            <template #header
                >Connect to existing shipment</template
            ></FindShipmentByHawbDialog
        >
        <ConnectToNewShipmentDialog
            v-if="showConnectToNewShipmentDialog && selectedItem"
            v-model="showConnectToNewShipmentDialog"
            :initial-form-data="connectToNewInitialFormData"
            :is-loading="isConnectingToNewShipment"
            @confirm="connectToNewShipment"
        ></ConnectToNewShipmentDialog>
        <UploadFileDialog
            v-if="showUploadDialog && selectedItem"
            v-model="showUploadDialog"
            :is-loading="isUploading"
            title="Upload pdf file"
            @confirm="onFileUploadConfirm"
            :rules="{
                allowedFileExtensions: ['pdf'],
                allowedMediaTypes: ['application/pdf'],
            }"
        ></UploadFileDialog>
    </Fragment>
</template>

<script setup lang="ts">
import { emitError, emitSuccess } from '@/event-bus';
import {
    downloadFile,
    getFileNameFromContentDispositionHeader,
} from '@/helpers/downloadHelper';
import {
    DutiesAndTaxesCheckUpApi,
    DutiesAndTaxesOverviewItem,
    Int32StringKeyValueItem,
} from '@/openapi';
import { DataOptions, DataTableHeader } from 'vuetify';
import ConnectToNewShipmentDialog from '../dialogs/ConnectToNewShipmentDialog.vue';
import { ConnectToNewShipmentForm } from '../dialogs/ConnectToNewShipmentDialog.vue';
import UploadFileDialog from '@/components/dialogs/UploadFileDialog.vue.html';
import { UploadFileForm } from '../dialogs/UploadFileDialog';
import { computed, ref, watch } from 'vue';
import { FooterOptions } from '@/types/types';
import FindShipmentByHawbDialog from '../dialogs/FindShipmentByHawbDialog.vue';

interface IsBusy {
    isLoading: boolean;
    isDeleting: boolean;
}

type EnrichedDutiesAndTaxesFile = DutiesAndTaxesOverviewItem & IsBusy;
const api = new DutiesAndTaxesCheckUpApi(undefined, '');

const headers = ref<DataTableHeader[]>([
    {
        text: 'File name',
        value: 'fileName',
        sortable: false,
        align: 'start',
        width: '200px',
    },
    {
        text: 'File extension',
        value: 'fileExtension',
        sortable: false,
        align: 'start',
        width: '130px',
    },
    {
        text: 'Create date',
        value: 'createDate',
        sortable: true,
        align: 'start',
        width: '200px',
    },
    {
        text: 'Charges zoll',
        value: 'chargesZoll',
        sortable: false,
        align: 'start',
        width: '200px',
    },
    {
        text: 'Charges EUSt',
        value: 'chargesEust',
        sortable: false,
        align: 'start',
        width: '200px',
    },
    {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'end',
        width: '100%',
    },
]);

const options = ref<DataOptions>({
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
});

const footerOptions = ref<FooterOptions>({
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
});

const items = ref<EnrichedDutiesAndTaxesFile[]>([]);
const selectedItem = ref<EnrichedDutiesAndTaxesFile | null>(null);
const totalAmountOfItems = ref(0);
const loading = ref(false);

const showDialog = ref(false);
const showConnectToNewShipmentDialog = ref(false);
const showUploadDialog = ref(false);
const isUploading = ref(false);
const connectToNewInitialFormData = ref<ConnectToNewShipmentForm | null>(null);
const isRetrievingPrefillData = ref(false);
const isConnectingToExistingShipment = ref(false);
const isConnectingToNewShipment = ref(false);

let timeoutDelay = 0;
watch(options, (newVal: any, oldVal: any) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(() => {
        getFiles();
    }, 250);
});

async function getFiles(page?: number) {
    page ??= options.value.page;
    loading.value = true;
    try {
        const response = await api.getDataOfDutiesAndTaxesFiles(
            page,
            options.value.itemsPerPage,
            sortBy.value,
            sortDesc.value
        );

        items.value =
            response.data.items?.map((item) => ({
                ...item,
                isDeleting: false,
                isLoading: false,
            })) ?? [];
        totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
    } catch {
        emitError('Something went wrong while retrieving the data.');
    }

    loading.value = false;
}

async function onDownloadFile(downloadId: number | null) {
    if (!downloadId) {
        return;
    }
    try {
        const response = await api.downloadDutiesAndTaxFile(downloadId, {
            responseType: 'blob',
        });
        const fileName = getFileNameFromContentDispositionHeader(response);
        if (fileName) {
            downloadFile(response.data, fileName);
        }
    } catch {
        emitError('Something went wrong while downloading the file.');
    }
}

async function deleteItem(item: EnrichedDutiesAndTaxesFile) {
    try {
        item.isDeleting = item.isLoading = true;
        await api.deleteDutiesAndTaxFile(item.items?.map((c) => c.id!));
        emitSuccess('Successfully deleted the item.');
        item.isDeleting = item.isLoading = false;
        await getFiles();
    } catch {
        loading.value = false;
        emitError('Something went wrong while deleting the file');
    }
}

async function onFileUploadConfirm(eventData: UploadFileForm) {
    await uploadFile(eventData.files![0]);
    await getFiles();
}

async function uploadFile(file: File) {
    isUploading.value = true;
    try {
        await api.uploadTaxFile(selectedItemIds.value[0], file);
        emitSuccess('Successfully uploaded the selected file');
    } catch (e: any) {
        const errorMessages =
            e?.response?.data?.detail ??
            'Something went wrong while uploading the .pdf file';
        emitError(errorMessages);
    }
    isUploading.value = false;
    showUploadDialog.value = false;
}

async function onConnectToNew(item: EnrichedDutiesAndTaxesFile) {
    const xmlFileId = item.items?.find(
        (c) => c.fileExtension?.toUpperCase() === 'XML'
    )?.id;
    if (xmlFileId) {
        try {
            isRetrievingPrefillData.value = true;
            const response = await api.getPrefillValues(xmlFileId);
            const data = response.data;
            connectToNewInitialFormData.value = {
                incotermId: data.incotermId!,
                chargesEust: data.chargesEust!,
                chargesZoll: data.chargesZoll!,
                invoiceName: data.invoiceName!,
                invoiceAddress: data.invoiceAddress!,
                invoiceZipCode: data.invoiceZipCode!,
                invoiceCity: data.invoiceCity!,
                hawbNumber: data.hawbNumber!,
            };
        } catch {
            emitError(
                'Something went wrong while retrieving the values to prefill the form fields'
            );
        }
        isRetrievingPrefillData.value = false;
    }

    showConnectToNewShipmentDialog.value = true;
    selectedItem.value = item;
}

async function onSelectedExistingShipment(eventData: Int32StringKeyValueItem) {
    isConnectingToExistingShipment.value = true;
    try {
        await api.connectToExistingShipment({
            shipmentId: eventData.key,
            dutiesAndTaxesFileIds: selectedItemIds.value,
        });
        selectedItem.value = null;
        await getFiles();
    } catch (e: any) {
        const errorMessages =
            e?.response?.data?.detail ??
            'Something went wrong while connecting the selected HAWB.';
        emitError(errorMessages);
    }
    isConnectingToExistingShipment.value = false;
}

async function connectToNewShipment(eventData: ConnectToNewShipmentForm) {
    isConnectingToNewShipment.value = true;
    try {
        await api.connectToNewShipment({
            dutiesAndTaxesFileIds: selectedItemIds.value,
            abfertigEza: eventData.abfertigEza ? eventData.abfertigEza : 0,
            beschaukosten: eventData.beschaukosten
                ? eventData.beschaukosten
                : 0,
            ccCharges: eventData.ccCharges ? eventData.ccCharges : 0,
            chargesEust: eventData.chargesEust ? eventData.chargesEust : 0,
            chargesOther: eventData.chargesOther ? eventData.chargesOther : 0,
            chargesOtherWithoutVat: eventData.chargesOtherWithoutVat
                ? eventData.chargesOtherWithoutVat
                : 0,
            chargesToSender: eventData.chargesToSender ?? false,
            chargesZoll: eventData.chargesZoll ? eventData.chargesZoll : 0,
            contactEmail: eventData.contactEmail,
            contactPhone: eventData.contactPhone,
            customerId: eventData.customerId!,
            frachtkosten: eventData.frachtkosten ? eventData.frachtkosten : 0,
            hawbNumber: eventData.hawbNumber!,
            lagerkosten: eventData.lagerkosten ? eventData.lagerkosten : 0,
            incotermId: eventData.incotermId,
            invoiceAddress: eventData.invoiceAddress,
            invoiceCity: eventData.invoiceCity,
            invoiceCountryId: eventData.invoiceCountryId,
            invoiceName: eventData.invoiceName,
            invoiceZipCode: eventData.invoiceZipCode,
            isClearedByCustomer: eventData.isClearedByCustomer,
            isEza: eventData.isEza,
            origin: eventData.origin,
            pieces: eventData.pieces ? eventData.pieces : 0,
            realWeight: eventData.realWeight ? eventData.realWeight : 0,
            shipperAddress: eventData.shipperAddress,
            shipperCity: eventData.shipperCity,
            shipperCountryId: eventData.shipperCountryId,
            shipperName: eventData.shipperName,
            shipperZipCode: eventData.shipperZipCode,
            transitT1: eventData.transitT1,
            value: eventData.value ? eventData.value : 0,
            valueCurrencyId: eventData.valueCurrencyId,
            vorlageengelt: eventData.vorlageengelt
                ? eventData.vorlageengelt
                : 0,
            zessionsgeb: eventData.zessionsgeb ? eventData.zessionsgeb : 0,
        });
        emitSuccess('Successfully connected to the new shipment.');
    } catch (e: any) {
        const errorMessages =
            e?.response?.data?.detail ??
            'Something went wrong while connecting to the new shipment.';
        emitError(errorMessages);
    }
    isConnectingToNewShipment.value = false;
}

const sortBy = computed(() => {
    return options.value.sortBy?.[0] ?? '';
});

const sortDesc = computed(() => {
    return options.value.sortDesc?.[0] ?? false;
});

const selectedItemIds = computed(() => {
    return selectedItem.value?.items?.map((c) => c.id!) ?? [];
});
</script>
