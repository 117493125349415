import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import * as CustomsExportController from '../api/CustomsExportController';
import CustomsExportShipmentDetailViewNew from './CustomsExportShipmentDetailViewNew.vue.html';
import { CustomsShipmentDetailViewModel } from '@/api/viewmodels/CustomsShipmentDetailViewModel';
import CustomsFlowStepsMixin from '@/mixins/CustomsFlowStepsMixin';
import ShipmentStatusFlow from './ShipmentStatusFlow.vue.html';
import ConfirmShipmentReasonDialog from './dialogs/ConfirmShipmentReasonDialog.vue.html';
import AddRouteDialog from './dialogs/AddRouteDialog.vue.html';
import AddMrnDialog from './dialogs/AddMrnDialog.vue.html';
import { getStatuses } from '@/helpers/statusHelper';
import { CustomsShipmentItemViewModel, CustomsWabApi, CustomsWabItem } from '@/openapi';
import CountriesMixin from '@/mixins/CountriesMixin';
import ShipmentPaperwork from './ShipmentPaperwork.vue.html';
import ToolbarMixin from '@/mixins/ToolbarMixin';
import FilterParcels from './FilterParcels.vue.html';

import ReturnShipmentButton from './buttons/ReturnShipmentButton.vue.html';
import CustomsInspectionButton from './buttons/CustomsInspectionButton.vue.html';
import HoldShipmentButton from './buttons/HoldShipmentButton.vue.html';

const customsWabApi = new CustomsWabApi(undefined, '');

interface Loadable {
    isLoading: boolean;
}

@Component({
    components: {
        CustomsExportShipmentDetailViewNew,
        ShipmentStatusFlow,
        ConfirmShipmentReasonDialog,
        AddRouteDialog,
        AddMrnDialog,
        ShipmentPaperwork,
        FilterParcels,
        ReturnShipmentButton,
        CustomsInspectionButton,
        HoldShipmentButton
    },
})
export default class CustomsExportWab extends mixins(CustomsFlowStepsMixin, CountriesMixin, ToolbarMixin) {
    @Prop({ default: '' })
    color!: string;

    isLoading = false;
    options: any = {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
    };
    shipmentOptions: any = {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
    };
    filter: any = {
        step: null,
        shipmentStatusDescription: '',
        parcels: [],
        exportMrn: '',
        shipperCountry: null,
        consigneeCountr: null
    };
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };
    totalItems: Number = 0;
    totalShipmentItems: Number = 0;
    expanded = [];

    simulateAutomatedStepButton = false;

    checkShipmentStep = 2;
    automatedSteps = [6, 9, 10, 11, 13]; // for testing

    statusFilter: number[] = [];
    shipmentStatusFilter = [
        'Hold',
        'Return',
        'Customs inspection',
        'Loading confirmed',
        'Waiting for customs release',
        'Customs EXIT released',
    ];

    currentHandlingWABItem: CustomsWabItem | null = null;
    currentHandlingItem: CustomsShipmentItemViewModel | null = null;
    currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;
    customsHandlingItems: CustomsShipmentItemViewModel[] = [];

    showDialogAddMrn = false;
    dialogAddMrnLoading = false;

    showDialogAddRoute = false;
    dialogAddRouteLoading = false;

    items = [] as Array<CustomsWabItem>;

    headers = [
        {
            text: 'WAB Number',
            value: 'wabNumber',
            align: 'start',
            width: '15%',
        },
        {
            text: 'Number of shipments in WAB',
            value: 'numberOfShipmentsInWab',
            align: 'start',
            width: '15%',
        },
        {
            text: 'Active',
            value: 'shipmentsAddedWarning',
            align: 'start',
            width: '5%',
        },
        { text: '', value: 'proceedToExport', align: 'start' },
    ];

    customsHandlingItemsHeaders = [
        {
            text: 'Step',
            value: 'exportFlowStatus',
            align: 'center',
        },
        {
            text: 'Shipment status',
            value: 'shipmentStatusDescription',
        },
        {
            text: 'HAWB/Parcel ID',
            value: 'hawb',
            sortable: false,
            width: '15em',
        },
        {
            text: 'MRN ',
            value: 'exportMrn',
            width: '18em',
        },
        {
            text: 'Anzahl ',
            value: 'pieces',
            sortable: false,
            align: 'end',
        },
        {
            text: 'Gewicht ',
            value: 'grossWeight',
            sortable: false,
            align: 'end',
        },
        { text: 'EORI', value: 'eori', sortable: false },
        {
            text: 'Versender',
            value: 'shipper',
            sortable: false,
            width: '15em',
        },
        {
            text: 'Empfänger',
            value: 'consignee',
            sortable: false,
            width: '15em',
        },
        {
            text: 'Warenwert',
            value: 'value',
            sortable: false,
            align: 'end',
        },
        { text: 'Währung', value: 'valueCurrency', sortable: false },
        {
            text: 'Warenbeschreibung / tarifnummer',
            value: 'articles',
            sortable: false,
            width: '25em',
        },
    ];

    timeInterval = 0;
    created() {
        this.statusFilter = getStatuses();

        // Update/retrieve the data every minute
        this.timeInterval = setInterval(() => {
            this.loadWabs(
                this.options.page,
                this.options.itemsPerPage,
                this.getSortField(this.options.sortBy),
                this.getSort(this.options.sortDesc)
            );
        }, 60000);

        this.addRefreshToolbarOption(this.reloadWabs);
    }

    destroyed() {
        clearInterval(this.timeInterval);
    }

    timeoutDelay = 0;
    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.loadWabs(
                newVal.page,
                newVal.itemsPerPage,
                this.getSortField(newVal.sortBy),
                this.getSort(newVal.sortDesc)
            );
        }, 250);
    }

    timeoutShipmentsDelay = 0;
    @Watch('shipmentOptions')
    onShipmentOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutShipmentsDelay);
        this.timeoutShipmentsDelay = setTimeout(() => {
            this.loadShipmentItems(
                this.currentHandlingWABItem!.id!,
                this.shipmentOptions.page
            );
        }, 250);
    }

    @Watch('expanded')
    onExpandedChanged(
        newVal: CustomsShipmentItemViewModel[],
        oldVal: CustomsShipmentItemViewModel[]
    ) {
        if (newVal.length === 0) {
            this.currentHandlingItem = null;
            this.currentShipmentDetail = null;
        } else {
            this.currentHandlingItem = newVal[0];

            this.isLoading = true;
            this.currentShipmentDetail = null;
            CustomsExportController.getShipmentDetail(
                this.currentHandlingItem.id!
            )
                .then(
                    (response) => (this.currentShipmentDetail = response.data)
                )
                .catch((error) =>
                    this.displayError(
                        'Something went wrong while retrieving the detail data.'
                    )
                )
                .finally(() => (this.isLoading = false));
        }
    }

    onExpandedWABItem({
        item,
        value,
    }: {
        item: CustomsWabItem;
        value: boolean;
    }) {
        if (value) {
            const isDifferentWab =
                this.currentHandlingWABItem &&
                item?.id != this.currentHandlingWABItem?.id;
            this.currentHandlingWABItem = item;
            if (isDifferentWab) {
                this.customsHandlingItems = [];
                this.loadShipmentItems(item.id!, 1);
            }
            // Retrieval of the related shipments is otherwise done in `onShipmentOptionsChanged`.
        } else {
            this.currentHandlingWABItem = null;
            this.customsHandlingItems = [];
        }
    }

    onProceedToExport(item: CustomsWabItem & Loadable) {
        this.currentHandlingWABItem = item;
        if (item.hasRoute) {
            item.isLoading = true;
            this.proceedToExport()
                .then(async () => {
                    await this.loadWabs(
                        this.options.page,
                        this.options.itemsPerPage,
                        this.getSortField(this.options.sortBy),
                        this.getSort(this.options.sortDesc)
                    );
                })
                .finally(() => (item.isLoading = false));
        } else {
            this.showDialogAddRoute = true;
        }
    }

    openDialogShipmentAddMrn(item: CustomsShipmentItemViewModel) {
        this.stopLoadingButtons();
        this.currentHandlingItem = item;
        this.showDialogAddMrn = true;
    }

    onFilterSelected() {
        this.loadShipmentItems(this.currentHandlingWABItem!.id!, 1);
    }

    getSortField(sortFields: string[]) {
        return sortFields[0] ?? '';
    }

    getSort(sortDesc: Boolean[]) {
        const isDesc = sortDesc[0] ?? null;
        if (!(isDesc === false || isDesc === true)) {
            return '';
        }
        return isDesc ? 'DESC' : 'ASC';
    }

    get loadableItems(): (CustomsWabItem & Loadable)[] {
        return this.items.map((item) => ({ ...item, isLoading: false }));
    }

    async loadWabs(
        page: number,
        itemsPerPage: number,
        orderByField: string,
        orderBy: string
    ) {
        return customsWabApi.getWabs(
            page,
            itemsPerPage,
            orderByField,
            orderBy
        )
            .then((response) => {
                if (response.status != 200) {
                    this.displayError(
                        'Something went wrong while retrieving the WABs.'
                    );
                }

                this.items = response.data.wabItems ?? [];
                this.totalItems = response.data.totalAmount ?? 0;
            })
            .catch((error) => {
                this.displayError(
                    'Something went wrong while retrieving the WABs.'
                );
            });
    }

    async reloadWabs() {
        this.options.page == 1
            ? await this.loadWabs(
                  this.options.page,
                  this.options.itemsPerPage,
                  this.getSortField(this.options.sortBy),
                  this.getSort(this.options.sortDesc)
              )
            : (this.options.page = 1);
    }

    async loadShipmentItems(wabId: number, page: number) {
        this.isLoading = true;
        try {
            const response = await customsWabApi.getWabShipments({
                wabId,
                page,
                itemsPerPage: this.shipmentOptions.itemsPerPage,
                step: this.filter.step ?? undefined,
                shipmentStatusDescription: this.filter.shipmentStatusDescription ?? undefined,
                parcels: this.filter.parcels,
                exportMrn: this.filter.exportMrn ?? undefined,
                shipperCountry: this.filter.shipperCountry ?? undefined,
                consigneeCountry: this.filter.consigneeCountry ?? undefined
            }
                );
            this.customsHandlingItems = response.data.shipments ?? [];
            this.totalShipmentItems = response.data.totalAmount ?? 0;
            this.currentHandlingWABItem!.numberOfShipmentsInWab =
                response.data.totalAmount ?? 0;
            this.shipmentOptions.page = page;
        } catch (error) {
            this.displayError(
                'Something went wrong while retrieving the corresponding shipments.'
            );
        }
        this.isLoading = false;
    }

    stopLoadingButtons() {
        this.simulateAutomatedStepButton = false;
    }

    closeAllDialogs() {
        this.showDialogAddMrn = false;
        this.showDialogAddRoute = false;
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }

    async proceedToExport(routeId?: number) {
        try {
            const response = await customsWabApi.proceedToExport({
                wabId: this.currentHandlingWABItem!.id,
                routeId: routeId,
            });
            this.currentHandlingWABItem = null;
            return response;
        } catch (e) {
            this.displayError(
                'Something went wrong during the "Proceed to export" action.'
            );
            throw e;
        }
    }

    async onRouteSelected(value: any) {
        this.dialogAddRouteLoading = true;
        this.proceedToExport(value.routeId)
            .then(async () => {
                await this.loadWabs(
                    this.options.page,
                    this.options.itemsPerPage,
                    this.getSortField(this.options.sortBy),
                    this.getSort(this.options.sortDesc)
                );
            })
            .finally(() => {
                this.dialogAddRouteLoading = false;
                this.showDialogAddRoute = false;
            });
    }

    async onMrnEntered({ mrn }: { mrn: string }) {
        this.dialogAddMrnLoading = true;
        try {
            await customsWabApi.setMrnAndRemoveShipmentFromWab(
                this.currentHandlingItem!.id,
                mrn
            );
            if (this.customsHandlingItems.length == 1) {
                await this.loadWabs(
                    this.options.page,
                    this.options.itemsPerPage,
                    this.getSortField(this.options.sortBy),
                    this.getSort(this.options.sortDesc)
                );
            } else {
                await this.loadShipmentItems(
                    this.currentHandlingWABItem!.id!,
                    this.shipmentOptions.page
                );
            }
        } catch (error) {
            this.displayError(
                'Something went wrong while setting the MRN and removing the shipment from the WAB.'
            );
        }

        this.dialogAddMrnLoading = false;
        this.showDialogAddMrn = false;
    }

    onShipmentStatusChanged({ success }: any) {
        if(success) {
            this.loadShipmentItems(this.currentHandlingWABItem!.id!, this.options.page);
        }
    }
}
