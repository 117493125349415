import { AxiosResponse } from 'axios';

export function downloadFile(file: Blob, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
}

export function getFileNameFromContentDispositionHeader(
    response: AxiosResponse
) {
    const contentDisposition: string = response.headers['content-disposition'];
    return contentDisposition?.match(/filename="?(.+?)"?;\s/)?.[1];
}
